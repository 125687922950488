
import { defineComponent, ref, watch ,onMounted} from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "BasicInfo",

  setup(props) {
    const _url = ref("https://web.7ugame.cn");
    const store = useStore();
    const report = (dataObj) => {
      const obj = {
        FReportType: 2,
        FEventSN: dataObj.code,
        FRelatedCode: dataObj.rcode,
        FRelatedDeviceCode: dataObj.dcode,
      };
      store
        .dispatch("point", { data: obj })
        .then((data) => {
          if (data.code != 0) {
            console.log(data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const downloadUrl = ref("");
    const getAppPack = () => {
      const obj = {
        // version: "2.0.3",
        tradeType: "android",
        channel: "yqwb"
      };
      store
        .dispatch("getAppPack", { data: obj })
        .then((res) => {
          if (res.errno === 0) {
            downloadUrl.value = res.data.downUrl;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    getAppPack();
    const isShowGzh = ref(false);
    const isShowDy = ref(false);
    const handleMouseOverDy = () => {
      isShowGzh.value = false;
      isShowDy.value = true;
    };

    const handleMouseLeaveDy = () => {
      // setTimeout(() => {
      isShowDy.value = false;
      // }, 2500);
    };

    const handleMouseOverWx = () => {
      isShowDy.value = false;
      isShowGzh.value = true;
    };

    const handleMouseLeaveWx = () => {
      // setTimeout(() => {
      isShowGzh.value = false;
      // }, 2500);
    };
    const toabout = (type) => {
      window.open(_url.value+"/about.html?type=" + type);
      if (type == 1) {
        const obj = {
          code: "W100082",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        const obj = {
          code: "W100083",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
    };
    const toIosDown = () => {
      const obj = {
        code: "W100080",
        rcode: "",
        dcode: "",
      };
      report(obj);
      window.open(
        "https://apps.apple.com/cn/app/%E4%B8%80%E8%B5%B7%E7%8E%A9%E5%90%A7-switch%E6%AF%94%E4%BB%B7%E5%B7%A5%E5%85%B7/id6444666413"
      );
    };

    const toyhxy = () => {
      window.open(_url.value+"/protocolls-web.html");
      const obj = {
        code: "W100084",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const toyszc = () => {
      window.open(_url.value+"/privacyls-web.html");
      const obj = {
        code: "W100085",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const tobeian = () => {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
      const obj = {
        code: "W100086",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const tojb1 = () => {
      window.open("https://www.shjbzx.cn/");
      const obj = {
        code: "W100087",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const tojb2 = () => {
      window.open("https://www.12377.cn/");
      const obj = {
        code: "W100088",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const changeColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#666";
    };

    const revertColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "";
    };
    onMounted(() => {
      if (window.location.href.indexOf("oa") > -1 || window.location.href.includes("localhost")) {
        _url.value = "https://oaweb.7ugame.cn";
      }
    });

    return {
      changeColor,
      revertColor,
      toIosDown,
      handleMouseLeaveDy,
      handleMouseLeaveWx,
      handleMouseOverWx,
      handleMouseOverDy,
      isShowGzh,
      isShowDy,
      toabout,
      tojb1,
      tojb2,
      tobeian,
      toyhxy,
      toyszc,
      downloadUrl,
    };
  },
});
