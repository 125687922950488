
// 节流函数
const throttle = (func: Function, delay: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    func(...args);
  };
};
import Message from "../components/Message.vue";
import creatwMessage from "../components/createMessage";
import { useRouter } from "vue-router";
import {
  defineComponent,
  computed,
  onUpdated,
  onMounted,
  watch,
  ref,
  toRefs,
} from "vue";
import "../css/base.css";
import Login from "../components/Login.vue";
import { useStore } from "vuex";

import { GlobalDataProps } from "../store";
import BasicInfo from "../components/BasicInfo.vue";

export default defineComponent({
  name: "Index",
  components: {
    Login,
    Message,
    BasicInfo,
  },

  setup() {
    const store = useStore<GlobalDataProps>();
    const report = (dataObj) => {
      const obj = {
        FReportType: 2,
        FEventSN: dataObj.code,
        FRelatedCode: dataObj.rcode,
        FRelatedDeviceCode: dataObj.dcode,
      };
      store
        .dispatch("point", { data: obj })
        .then((data) => {
          if (data.code != 0) {
            console.log(data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const isShowTitle = ref(false);
    const isTitleOver = ref(false);
    const userCode = localStorage.getItem("userCode");
    const router = useRouter();
    const gameName = ref();
    const gameCode = ref();
    const isShowChangeGame = ref(false);
    const isMoreData = ref(true);
    const msg = ref("");
    const FPage = ref(1);
    const FPageSize = ref(20);
    const FLabelValue = ref("-1");
    const showLoginModal = ref(false);
    const showMessageModal = ref(false);
    const handleLoginSuccess = () => {
      showLoginModal.value = false;
      // 处理登录成功后的逻辑
    };

    const currentUser = computed(() => store.state.user);
    const testData = ref<any[]>([]);
    const gameList = ref<any[]>([]);
    const gameListItem = ref<any[]>([]);
    const error = computed(() => store.state.error);
    const isLoading = computed(() => store.state.loading);
    const isLogin = computed(() => store.state.user.isLogin);
    const isShowGzh = ref(false);
    const isShowDy = ref(false);
    // const router = useRouter();
    const activeTab = ref("热门");
    const toDetail = (id, type) => {
      const obj = {
        code: "W100056",
        rcode: "",
        dcode: "",
      };
      report(obj);
      router.push({ path: `/articleDetail/${id}/${type}` });
    };
    const markLikeState = (id, status) => {
      const obj = {
        FType: 4,
        FReferCode: id,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("behaviorLike", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const changeSBg = (type) => {
      const elements = document.querySelectorAll(".share-item");
      elements.forEach((element) => {
        if (element && element.textContent === type) {
          element.classList.remove("bgfff");
          element.classList.add("bgff6");
        }
      });
    };

    const changeSBg2 = (type) => {
      const elements = document.querySelectorAll(".share-item");
      elements.forEach((element) => {
        if (element && element.textContent === type) {
          element.classList.remove("bgff6");
          element.classList.add("bgfff");
        }
      });
    };
    const changeLikeStatus = (id, type) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      //  type:2取消喜欢 1喜欢
      const data = testData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.FIsLike = !element.FIsLike;
          if (type == 2) {
            element.FLikeCount--;
          } else if (type == 1) {
            element.FLikeCount++;
          }
        }
      });
      markLikeState(id, type);
      if (type == 1) {
        const obj = {
          code: "W100060",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      if (type == 2) {
        const obj = {
          code: "W100061",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
    };
    const toToolDetail = (type, id) => {
      if (type == 1) {
        // 地图
        window.open("https://img.7ugame.cn/map/zelda/index0.html");
      } else {
        router.push({ path: `/toolDetail/${id}/${gameCode.value}` });
        // router.push({ path: "/toolDetail", query: { id: id, code: gameCode.value } });
      }
      const obj = {
        code: "W100065",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const showFullContent = (element) => {
      if (element.scrollWidth > element.clientWidth) {
        // element.title = element.textContent;
        console.log("截断");
      }
    };
    const markFollowState = (status, uid, id) => {
      const obj = {
        FType: 2,
        FRelationCode: uid,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("focusSetup", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            //  type:2取消关注 1关注
            const data = testData.value;
            data.forEach((element) => {
              if (element.FId == id) {
                element.FFollowStatus = status;
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const changeFollowStatus = (id, type, uid) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      if (type == 1) {
        const obj = {
          code: "W100055",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        const obj = {
          code: "W100054",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }

      markFollowState(type, uid, id);
    };

    const markState = (id, status) => {
      const obj = {
        FType: 3,
        FReferCode: id,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("uninterested", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const getArtical = () => {
      const obj = {
        FChannelType: 1,
        FChannelCode: 0,
        FLabelValue: FLabelValue.value,
        FPage: FPage.value,
        FPageSize: FPageSize.value,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("richTextList", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            if (data.data.FTotal == 0) {
              isMoreData.value = false;
              return;
            }
            testData.value = [...testData.value, ...data.data.FListData];

            const td = testData.value;
            td.forEach((item) => {
              item.FTopics.forEach((item2) => {
                item2.isHover = false;
              });
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      FPage.value += 1;
    };
    const getGameListItem = (id) => {
      const obj = {
        FGameToolId: id,
        FPage: 1,
        FPageSize: 6,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("gameListItem", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            gameListItem.value = [...data.data.FListData];
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      FPage.value += 1;
    };

    const getGameList = () => {
      const obj = {
        FPage: 1,
        FPageSize: 10,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("gameList", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            gameList.value = [...data.data.FListData];
            gameName.value = data.data.FListData[0].FGameName;
            gameCode.value = data.data.FListData[0].FGameCode;
            getGameListItem(data.data.FListData[0].FGameToolId);
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      FPage.value += 1;
    };

    const changeGame = () => {
      isShowTitle.value = false;
      isShowChangeGame.value = !isShowChangeGame.value;

      const obj = {
        code: "W100063",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const changeGameItem = (id, name, code) => {
      gameName.value = name;
      gameCode.value = code;
      isShowChangeGame.value = !isShowChangeGame.value;
      getGameListItem(id);

      setTimeout(() => {
        const gametoolTitle = document.querySelector(
          ".gametool-title"
        ) as HTMLElement;
        if (
          gametoolTitle.scrollWidth > gametoolTitle.clientWidth ||
          gametoolTitle.offsetWidth < gametoolTitle.scrollWidth
        ) {
          isTitleOver.value = true;
        } else {
          isTitleOver.value = false;
        }
      }, 200);

      const obj = {
        code: "W100064",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const { getArticalList } = toRefs({
      getArticalList: throttle(() => {
        if (isMoreData.value) {
          getArtical();
        }
      }, 3000),
    });

    const changeTab = (tab: string) => {
      if (tab == "关注") {
        if (!isLogin.value) {
          showLoginModal.value = true;
          return;
        } else {
          FLabelValue.value = "-3";
        }
        const obj = {
          code: "W100051",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      if (tab == "热门") {
        FLabelValue.value = "-1";
        const obj = {
          code: "W100052",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      if (tab == "最新") {
        FLabelValue.value = "-2";
        const obj = {
          code: "W100053",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }

      isMoreData.value = true;
      testData.value = [];
      FPage.value = 1;
      getArtical();
      activeTab.value = tab;
    };
    const items = ref([
      { id: 1, color: "#93a063" },
      { id: 2, color: "#638ca0" },
      { id: 3, color: "#a06363" },
      { id: 4, color: "#93a063" },
      { id: 5, color: "#638ca0" },
      { id: 6, color: "#a06363" },
      // Add more items as needed
    ]);

    // const changeColor = (event: MouseEvent) => {
    //     const target = event.target as HTMLElement
    //     target.style.color = '#68A5E1'
    // }

    // const revertColor = (event: MouseEvent) => {
    //     const target = event.target as HTMLElement
    //     target.style.color = ''

    // }

    const changeBg = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.background = "#E5E5E5";
    };

    const changeBg2 = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.background = "#fff";
    };

    const hideContent = (id) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      const data = testData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isHide = true;
        }
      });
      markState(id, 1);
      const obj = {
        code: "W100058",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };
    const openContent = (id) => {
      const data = testData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isHide = false;
          element.isShowDislike = false;
        }
      });
      markState(id, 2);
      const obj = {
        code: "W100059",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const showDislike = (id) => {
      const data = testData.value;
      data.forEach((element) => {
        element.isShowDislike = false;
        if (element.FId == id) {
          element.isShowDislike = true;
        }
      });
    };

    const closeDislike = (id) => {
      const data = testData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isShowDislike = false;
        }
      });
    };

    const toSearchDetail = (info) => {
      const obj = {
        code: "W100057",
        rcode: "",
        dcode: "",
      };
      report(obj);
      if (isLogin.value) {
        if (localStorage.getItem("searchInfo")) {
          const originalSearchInfo = localStorage.getItem("searchInfo");
          const originalSearchInfoArray = originalSearchInfo
            ? originalSearchInfo.split(",")
            : [];
          if (!originalSearchInfoArray.includes(info)) {
            originalSearchInfoArray.push(info);
          }
          const newSearchInfo = originalSearchInfoArray.join(",");
          localStorage.setItem("searchInfo", newSearchInfo);
        } else {
          const newSearchInfo = info;
          localStorage.setItem("searchInfo", newSearchInfo);
        }
        const sarr = localStorage.getItem("searchInfo");
      }

      // router.push({ path: "/searchDetail", query: { info: info } });
      router.push({ path: `/searchDetail/${info}` });
      store.commit("setIsSearch", true);
    };

    const infoItem = ref<HTMLElement | null>(null);
    const showScrollTopButton = ref(false);

    const handleScroll = () => {
      if (infoItem.value) {
        if (infoItem.value.scrollTop > infoItem.value.clientHeight * 3) {
          showScrollTopButton.value = true;
        } else {
          showScrollTopButton.value = false;
        }
      }
    };

    const scrollTop = () => {
      if (infoItem.value) {
        infoItem.value.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    const changeColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#68A5E1";
    };
    const changeColorTip = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#68A5E1";
    };

    const revertColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "";
    };

    const revertColorTip = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#999999";
    };

    const enlargeImage = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.transform = "scale(1.1)";
    };

    const reduceImage = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.transform = "";
    };

    const handleCloseModal = ($event) => {
      // 不感兴趣
      if (!$event.target.closest(".dislike-panel")) {
        const data = testData.value;
        data.forEach((element) => {
          element.isShowDislike = false;
        });
      }
      // 游戏工具
      if (!$event.target.closest(".gametool-panel")) {
        isShowChangeGame.value = false;
      }
    };

    onMounted(() => {
      getArtical();
      getGameList();

      const element = document.querySelector(".info-item") as HTMLElement;
      infoItem.value = element;
      infoItem.value.addEventListener("scroll", handleScroll);
      const main = document.querySelector(".main") as HTMLElement;
      const right = document.querySelector(".right") as HTMLElement;

      // 监听滚动处理
      main.addEventListener("wheel", (event) => {
        element.scrollTop += event.deltaY;
        right.scrollTop += event.deltaY;
        event.preventDefault();
        const distanceToBottom =
          element.scrollHeight - (element.scrollTop + element.clientHeight);
        if (distanceToBottom <= 100) {
          getArticalList.value();
        }
      });
    });
    watch(
      () => error.value.status,
      () => {
        const { status, message } = error.value;
        if (status && message) {
          creatwMessage(message, "error");
        }
      }
    );

    return {
      showFullContent,
      isShowTitle,
      isTitleOver,
      handleCloseModal,
      enlargeImage,
      reduceImage,
      changeColorTip,
      changeColor,
      revertColor,
      revertColorTip,
      showScrollTopButton,
      scrollTop,
      isMoreData,
      toSearchDetail,
      toToolDetail,
      changeGameItem,
      gameName,
      gameCode,
      isShowChangeGame,
      changeGame,
      getGameList,
      gameList,
      getGameListItem,
      gameListItem,
      userCode,
      toDetail,
      changeFollowStatus,
      markLikeState,
      changeLikeStatus,
      markState,
      getArtical,
      FPage,
      FPageSize,
      msg,
      showMessageModal,
      showLoginModal,
      handleLoginSuccess,
      openContent,
      hideContent,
      changeSBg,
      changeSBg2,
      changeBg,
      changeBg2,
      testData,
      showDislike,
      closeDislike,
      isShowGzh,
      isShowDy,
      activeTab,
      changeTab,
      getArticalList,
      items,
      currentUser,
      isLoading,
      isLogin,
      error,
    };
  },
});
