
// 节流函数
const throttle = (func: Function, delay: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    func(...args);
  };
};
import {
  defineComponent,
  ref,
  onUpdated,
  PropType,
  onMounted,
  toRefs,
  provide,
  computed,
  nextTick,
} from "vue";
import Login from "./Login.vue";
import { UserProps } from "../store";
import { useStore } from "vuex";
import createMessage from "../components/createMessage";
import Message from "../components/Message.vue";
import { useRouter } from "vue-router";
import { GlobalDataProps } from "../store";

export default defineComponent({
  name: "GlobalHeader",
  components: {
    Login,
    Message,
  },
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const router = useRouter();
    const csc = document.querySelector(".c-scroll-container") as HTMLElement;
    const lsc = document.querySelector(".l-scroll-container") as HTMLElement;
    const usc = document.querySelector(".u-scroll-container") as HTMLElement;
    const ssc = document.querySelector(".s-scroll-container") as HTMLElement;

    // 监听滚动处理
    csc.addEventListener("scroll", () => {
      const { scrollTop, scrollHeight, clientHeight } = csc;
      if (scrollHeight - scrollTop - clientHeight <= 100) {
        this.getCommentList();
      }
    });

    lsc.addEventListener("scroll", () => {
      const { scrollTop, scrollHeight, clientHeight } = lsc;
      if (scrollHeight - scrollTop - clientHeight <= 100) {
        this.getLikeCollectList();
      }
    });

    usc.addEventListener("scroll", () => {
      const { scrollTop, scrollHeight, clientHeight } = usc;
      if (scrollHeight - scrollTop - clientHeight <= 100) {
        this.getFocusMessageList();
      }
    });

    ssc.addEventListener("scroll", () => {
      const { scrollTop, scrollHeight, clientHeight } = ssc;
      if (scrollHeight - scrollTop - clientHeight <= 100) {
        this.getsystemMessageList();
      }
    });
  },
  emits: ["changeFocusTrue", "changeFocusFalse"],
  setup(props, { emit }) {
    // const isFocus = ref(false)  //选中输入框
    const isShowInfo2 = ref(false);
    const isFocus = ref(props.isFocus);
    // const closeModal = ($event) => {
    //   if (!$event.target.closest('.header-input')) {
    //     emit('close-modal');
    //     console.log('2--isFocus.value', isFocus.value)
    //   }
    // }
    const changeFocusTrue = () => {
      emit("changeFocusTrue");
    };
    const changeFocusFalse = () => {
      emit("changeFocusFalse");
    };

    const store = useStore<GlobalDataProps>();
    const report = (dataObj) => {
      const obj = {
        FReportType: 2,
        FEventSN: dataObj.code,
        FRelatedCode: dataObj.rcode,
        FRelatedDeviceCode: dataObj.dcode,
      };
      store
        .dispatch("point", { data: obj })
        .then((data) => {
          if (data.code != 0) {
            console.log(data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const searchInfo = ref("");
    const searchInfoArr = ref<any[]>([]);
    const isCMoreData = ref(true);
    const isLMoreData = ref(true);
    const isUMoreData = ref(true);
    const isSMoreData = ref(true);
    const inputFocus = ref(false);
    const CFPage = ref(1);
    const LFPage = ref(1);
    const UFPage = ref(1);
    const SFPage = ref(1);
    const FSysNum = ref();
    const refreshMessage = computed(() => store.state.refresh.message);
    const refreshLike = computed(() => store.state.refresh.like);
    const refreshInfo = computed(() => store.state.refresh.info);
    const refreshUser = computed(() => store.state.refresh.user);
    const FTotalNum = computed(() => store.state.user.FTotalNum);
    const isSearch = computed(() => store.state.user.isSearch);
    // const FTotalNum = ref();
    const FSysArr = ref<any[]>([]);
    const FCommentArr = ref<any[]>([]);
    const FLikeCollectNum = ref(6);
    const FLikeCollectArr = ref<any[]>([]);
    const FFocusNum = ref();
    const FFocusArr = ref<any[]>([]);
    const currentTab = ref("info");
    const hoverIndex = ref(-1);
    const router = useRouter();
    const isShowLogout = ref(false);
    const showLoginModal = ref(false);
    const showLogout = ref(false);
    const handleLoginSuccess = () => {
      showLoginModal.value = false;
      // 处理登录成功后的逻辑
    };

    const isShowLoginInfo = ref(false);
    const showMessageModal = ref(false);
    const msg = ref("haha"); // 定义消息内容
    const toIndex = () => {
      const obj = {
        code: "W100001",
        rcode: "",
        dcode: "",
      };
      report(obj);
      router.push("/");
      // router.push({ path: "/skeletonDemo"});
      store.commit("setIsSearch", false);
    };
    const toInfoDetail = (type) => {
      router.push({ path: `/infoDetail/${type}` });
    };
    const inputRef = ref<HTMLInputElement | null>(null);
    const setInputFocus = () => {
      nextTick(() => {
        if (inputRef.value) {
          inputRef.value.focus();
        }
      });
    };
    const checkKey = (item) => {
      if (inputRef.value) {
        setInputFocus();
      }
      searchInfo.value = item;

      // isFocus.value = false
      changeFocusFalse();
    };

    const changeFocus = () => {
      inputFocus.value = true;
      const sarr = localStorage.getItem("searchInfo");
      if (sarr != null) {
        searchInfoArr.value = sarr.split(",");
        if (searchInfoArr.value[0] != "") {
          changeFocusTrue();
        }
      }
    };
    const toSearchDetail = () => {
      if (searchInfo.value != "") {
        if (props.user.isLogin) {
          if (localStorage.getItem("searchInfo")) {
            const originalSearchInfo = localStorage.getItem("searchInfo");
            const originalSearchInfoArray = originalSearchInfo
              ? originalSearchInfo.split(",")
              : [];
            if (!originalSearchInfoArray.includes(searchInfo.value)) {
              // 如果 `searchInfo.value` 不在数组中，则添加到数组中
              originalSearchInfoArray.unshift(searchInfo.value);
            }
            const newSearchInfo = originalSearchInfoArray.join(",");
            localStorage.setItem("searchInfo", newSearchInfo);
          } else {
            const newSearchInfo = searchInfo.value;
            localStorage.setItem("searchInfo", newSearchInfo);
          }
          const sarr = localStorage.getItem("searchInfo");
          if (sarr != null) {
            searchInfoArr.value = sarr.split(",");
          }
        }
        router.push({ path: `/searchDetail/${searchInfo.value}` });
        // router.push({ path: "/searchDetail",query: { info: searchInfo.value }, });
        store.commit("setIsSearch", true);
        searchInfo.value = "";
        const obj = {
          code: "W100002",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      inputFocus.value = false;
    };

    const handleMouseLeave = () => {
      // setTimeout(() => {
      if (!isShowLogout.value) {
        showLogout.value = false;
      }
      // }, 3000);
    };

    const setHoverBackground = (index) => {
      hoverIndex.value = index;
    };

    const resetHoverBackground = () => {
      hoverIndex.value = -1;
    };

    const handleLogout = () => {
      showLogout.value = false;
      // logout
      store
        .dispatch("logout", { loginData: "" })
        .then((data) => {
          if (data.errno === 0) {
            // console.log(data)
            localStorage.setItem("searchInfo", "");
            window.location.reload();
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.errmsg;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const changeBg = () => {
      isShowLogout.value = true;
      showLogout.value = true;
      const element = document.querySelector(".bgf");
      if (element) {
        element.classList.remove("bgff");
        element.classList.add("bgf6");
      }
    };
    const changeBg2 = () => {
      isShowLogout.value = false;
      setTimeout(() => {
        showLogout.value = false;
      }, 2000);
      const element = document.querySelector(".bgf");
      if (element) {
        element.classList.remove("bgf6");
        element.classList.add("bgff");
      }
    };
    const getUnReads = () => {
      const obj = {};
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("unReads", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            FSysNum.value = data.data.FSysNum;
            FLikeCollectNum.value = data.data.FLikeCollectNum;
            FFocusNum.value = data.data.FFocusNum;
            store.commit(
              "setFTotalNum",
              data.data.FSysNum +
                data.data.FLikeCollectNum +
                data.data.FFocusNum
            );
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    // 评论
    const getComment = () => {
      const obj = {
        FType: 1,
        FPage: CFPage.value,
        FPageSize: 20,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("comment", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            if (data.data.FListData.length == 0) {
              isCMoreData.value = false;
            }
            FCommentArr.value = [...FCommentArr.value, ...data.data.FListData];
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      CFPage.value++;
    };

    const { getCommentList } = toRefs({
      getCommentList: throttle(() => {
        if (isCMoreData.value) {
          getComment();
        }
      }, 3000),
    });

    // 点赞
    const getLikeCollect = () => {
      const obj = {
        FPage: LFPage.value,
        FPageSize: 20,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("likeCollect", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            if (data.data.FListData.length == 0) {
              isLMoreData.value = false;
            }
            FLikeCollectArr.value = [
              ...FLikeCollectArr.value,
              ...data.data.FListData,
            ];
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      LFPage.value++;
    };

    const { getLikeCollectList } = toRefs({
      getLikeCollectList: throttle(() => {
        if (isLMoreData.value) {
          getLikeCollect();
        }
      }, 3000),
    });

    // 关注
    const getFocusMessage = () => {
      const obj = {
        FPage: UFPage.value,
        FPageSize: 20,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("focusMessageList", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            if (data.data.FListData.length == 0) {
              isUMoreData.value = false;
            }
            FFocusArr.value = [...FFocusArr.value, ...data.data.FListData];
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      UFPage.value++;
    };

    const { getFocusMessageList } = toRefs({
      getFocusMessageList: throttle(() => {
        if (isUMoreData.value) {
          getFocusMessage();
        }
      }, 3000),
    });

    // 系统
    const getsystemMessage = () => {
      const obj = {
        FPage: SFPage.value,
        FPageSize: 20,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("systemMessageList", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            if (data.data.FListData.length == 0) {
              isSMoreData.value = false;
            }
            FSysArr.value = [...FSysArr.value, ...data.data.FListData];
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      SFPage.value++;
    };

    const { getsystemMessageList } = toRefs({
      getsystemMessageList: throttle(() => {
        if (isSMoreData.value) {
          getsystemMessage();
        }
      }, 3000),
    });
    // 一键已读
    const clearAll = () => {
      const obj = {};
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("clearAll", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            // zheli
            FSysNum.value = 0;
            const fs = FSysArr.value;
            fs.forEach((item) => {
              item.FReadStatus = 1;
            });
            const fl = FLikeCollectArr.value;
            fl.forEach((item) => {
              item.FReadStatus = 1;
            });
            const ff = FFocusArr.value;
            ff.forEach((item) => {
              item.FReadStatus = 1;
            });
            FLikeCollectNum.value = 0;
            FFocusNum.value = 0;
            store.commit("setFTotalNum", 0);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const readAll = () => {
      // FSysNum.value = 0
      // FLikeCollectNum.value = 0
      // FFocusNum.value = 0
      // // FTotalNum = 0
      // store.commit('setFTotalNum', 0);
      clearAll();
    };
    const clearSearch = () => {
      const obj = {
        code: "W100007",
        rcode: "",
        dcode: "",
      };
      report(obj);
      localStorage.setItem("searchInfo", "");
      searchInfoArr.value = [];
      inputFocus.value = false;
    };
    const changeTab = (tab) => {
      if (currentTab.value != tab) {
        if (tab == "message") {
          getComment();
          const obj = {
            code: "W100128",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
        if (tab == "user") {
          getFocusMessage();
          FFocusNum.value = 0;
          const obj = {
            code: "W100130",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
        if (tab == "like") {
          FLikeCollectNum.value = 0;
          getLikeCollect();
          const obj = {
            code: "W100129",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
        if (tab == "info") {
          FSysNum.value = 0;
          getsystemMessage();
          const obj = {
            code: "W100131",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
      }
      currentTab.value = tab;
      store.commit(
        "setFTotalNum",
        FSysNum.value + FFocusNum.value + FLikeCollectNum.value
      );
    };
    const changeFocusStyle = (event: MouseEvent) => {
      // isFocus.value = true

      changeFocus();
      // changeFocusTrue();
      // inputFocus.value = true;
      const sarr = localStorage.getItem("searchInfo");
      if (sarr != null) {
        searchInfoArr.value = sarr.split(",");
      }
      // if (event.target === event.currentTarget) {
      //   const target = event.target as HTMLElement;
      //   target.style.background = "#f6f6f6";
      // }
      // const element = document.querySelector(".h-input-t") as HTMLElement;
      // element.style.background = "#f6f6f6";
      // element.style.borderColor = "#333333";
    };

    const resetFocusStyle = (event: MouseEvent) => {
      if (!isFocus.value) {
        // if (event.target === event.currentTarget) {
        //   const target = event.target as HTMLElement
        //   target.style.borderColor = '#f6f6f6'

        // }

        const element = document.querySelector(".h-input-t") as HTMLElement;
        const inputElement = element.querySelector("input");
        if (inputElement) {
          // inputElement.style.borderColor = '#FFF'
        }
      }
    };

    const changeInpStyle = (event: MouseEvent) => {
      if (event.target === event.currentTarget && !isFocus.value) {
        const target = event.target as HTMLElement;
        target.style.background = "#FFF";
        const inputElement = target.querySelector("input");
        if (inputElement) {
          inputElement.style.background = "#FFF";
          inputElement.style.borderColor = "#FFF";
        }
        target.style.borderColor = "#333333";
      }
    };

    const resetInpStyle = (event: MouseEvent) => {
      if (event.target === event.currentTarget && !isFocus.value) {
        const target = event.target as HTMLElement;
        target.style.background = "#F6F6F6";
        const inputElement = target.querySelector("input");
        if (inputElement) {
          inputElement.style.background = "#F6F6F6";
          inputElement.style.borderColor = "#F6F6F6";
        }
        target.style.borderColor = "#F6F6F6";
      }
    };

    // const changeFocus2 = () => {
    //   isFocus.value = true

    //   changeFocusStyle()
    //   // changeFocusTrue()
    //   // if (inputRef.value) {
    //   //   setInputFocus()
    //   // }

    // }
    const isShowZan = ref(false);
    const isShowPl = ref(false);
    const isShowGz = ref(false);
    const isShowXttz = ref(false);
    

    const degnlu = () => {
      showLoginModal.value = true;
    };

    onMounted(() => {
      if (props.user.isLogin) {
        getUnReads(),
          //  getComment();
          getsystemMessage();
        const sarr = localStorage.getItem("searchInfo");
        if (sarr != null) {
          searchInfoArr.value = sarr.split(",");
        }
      }
    });

    return {
      isShowZan,
      isShowPl,
      isShowGz,
      isShowXttz,
      refreshMessage,
      refreshUser,
      refreshLike,
      refreshInfo,
      degnlu,
      changeFocusTrue,
      changeFocusFalse,
      checkKey,
      inputRef,
      // isFocus,
      // closeModal,
      changeInpStyle,
      changeFocusStyle,
      resetFocusStyle,
      resetInpStyle,
      changeFocus,
      // changeFocus2,
      clearSearch,
      inputFocus,
      searchInfoArr,
      searchInfo,
      isSearch,
      clearAll,
      toInfoDetail,
      toSearchDetail,
      getCommentList,
      getsystemMessageList,
      getFocusMessageList,
      getLikeCollectList,
      isCMoreData,
      isLMoreData,
      isUMoreData,
      isSMoreData,
      CFPage,
      LFPage,
      UFPage,
      SFPage,
      readAll,
      FFocusNum,
      FLikeCollectNum,
      FSysNum,
      FTotalNum,
      FCommentArr,
      FSysArr,
      FLikeCollectArr,
      FFocusArr,
      getUnReads,
      changeTab,
      currentTab,
      setHoverBackground,
      resetHoverBackground,
      hoverIndex,
      toIndex,
      isShowInfo2,
      isShowLoginInfo,
      showMessageModal,
      msg,
      isShowLogout,
      showLogout,
      showLoginModal,
      changeBg,
      changeBg2,
      handleLoginSuccess,
      handleMouseLeave,
      handleLogout,
    };
  },
});
