
import { useRoute } from "vue-router";
import {
  defineComponent,
  computed,
  onUpdated,
  onMounted,
  watch,
  ref,
  toRefs,
  nextTick,
  inject,
} from "vue";
import "../css/base.css";
import { useStore } from "vuex";

export default defineComponent({
  name: "AppleSignInSuss",

  setup() {
    const store = useStore();
    const _url = ref("https://api.7ugame.cn");
   
 
    const getUserInfo = () => {
      const obj = {};
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("fetchCurrentUser", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            store.state.user = { isLogin: true, ...data.data };
            localStorage.setItem("userImg", data.data.FCoverPath);
            store.state.user.img = data.data.FCoverPath;
            store.state.user.userCode = data.data.FUserCode;
            localStorage.setItem("userCode", data.data.FUserCode);
            // window.location.reload();

            
            const url = localStorage.getItem("page") as string;
            if (url) {
              window.location.href = url;
            }

          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const toLogin = (token) => {
      const obj = {
        identityToken: token,
      };
      store
        .dispatch("appleRegister", { data: obj })
        .then((data) => {
          if (data.errno === 0) {
            // console.log("登录成功");
            getUserInfo();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    onMounted(() => {
      const route = useRoute();

      // :state/:code/:id_token
      if (window.location.href.indexOf("oa") > -1 || window.location.href.includes("localhost")) {
        _url.value = "https://oaapi.7ugame.cn";
      }
      localStorage.setItem("state", "apple");
      toLogin(route.params.id_token);
    });

    return {

    };
  },
});
