
import Message from "../components/Message.vue";
import { GlobalDataProps } from "../store";
import { useRouter } from "vue-router";
import creatwMessage from "../components/createMessage";
import { useStore } from "vuex";
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  toRefs,
  computed,
} from "vue";
// import Message from "../components/Message.vue";
import BasicInfo from "../components/BasicInfo.vue";
// import PromptBox from "../components/PromptBox.vue";
import { useRoute } from "vue-router";
import Login from "../components/Login.vue";
import { isTypeParameterDeclaration } from "typescript";
// import WeiboSDK from "weibo-sdk";
// import {ArticleInfo} from '../testData'

// 节流函数
const throttle = (func: Function, delay: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    func(...args);
  };
};

export default defineComponent({
  name: "ArticleDetail",
  components: {
    BasicInfo,
    Message,
    Login,
  },
  mounted() {
    const contentRef = this.$refs.contentRef as HTMLElement;
    const main = document.querySelector(".main") as HTMLElement;

    // 监听滚动处理
    main.addEventListener("wheel", (event) => {
      contentRef.scrollTop += event.deltaY;
      event.preventDefault();
      const distanceToBottom =
        contentRef.scrollHeight -
        (contentRef.scrollTop + contentRef.clientHeight);
      if (distanceToBottom <= 100) {
        //    滚动到底部
        this.getArticalCommentList();
      }
    });
  },
  setup(props, { emit }) {
    const isShowFBB = ref(false);
    const _url = ref("https://web.7ugame.cn");
    const store = useStore<GlobalDataProps>();
    const report = (dataObj) => {
      const obj = {
        FReportType: 2,
        FEventSN: dataObj.code,
        FRelatedCode: dataObj.rcode,
        FRelatedDeviceCode: dataObj.dcode,
      };
      store
        .dispatch("point", { data: obj })
        .then((data) => {
          if (data.code != 0) {
            console.log(data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const FTipContent = ref("");
    const showBoxModal = ref(false);
    const showBoxModalType = ref(0);
    const commentCount = ref(0);
    const commentInfo = ref("");
    const richTextContent = ref(``);
    const showBriefDetail = ref(false);
    const briefDetailOver = ref(false);
    const isLogin = computed(() => store.state.user.isLogin);
    const userCode = localStorage.getItem("userCode");
    const commentList = ref<any[]>([]);
    const FGamesInfo = ref({});
    const id = ref();
    const isMoreData = ref(true);
    const showLoginModal = ref(false);
    const handleLoginSuccess = () => {
      showLoginModal.value = false;
      // 处理登录成功后的逻辑
    };

    const FPage = ref(1);
    const FPageSize = ref(20);
    const msg = ref("");
    const showMessageModal = ref(false);
    const videoUrl = ref(
      "http://1500016248.vod2.myqcloud.com/cba05364vodsh1500016248/df8db39c1397757885852835183/tkAmRESm3V4A.mp4"
    );
    const imageUrl = ref(
      "https://img2.baidu.com/it/u=2383848325,95647727&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800"
    );
    const showPreview = ref(false);
    const openPreview = (url) => {
      imageUrl.value = url;
      showPreview.value = true;
    };
    const tipList = ref<any[]>([]);
    const doMoreOptId = ref(0);
    const doMoreOpt = (id, type) => {
      doMoreOptId.value = id;
      const commentListData = commentList.value;
      commentListData.forEach((element) => {
        element.isShowPanel = false;
        if (element.FId == id) {
          if (type == 1) {
            element.isShowPanel = true;
          } else {
            element.isShowPanel = false;
          }
        }
      });
    };

    const getTip = () => {
      const obj = {
        FBizType: 2,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("tipOffItemCommon", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            tipList.value = data.data.FLabels.FItems;

            const tl = tipList.value;
            tl.forEach((element) => {
              element.checked = false;
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const checkTipId = ref(0);
    const checkTipTitle = ref("");
    const checkTip = (value, title) => {
      checkTipId.value = value;
      checkTipTitle.value = title;
      const tl = tipList.value;
      tl.forEach((element) => {
        element.checked = false;
        if (element.FValue == value) {
          element.checked = true;
        }
      });
    };
    const submitTip = () => {
      if (checkTipTitle.value == "其他" && FTipContent.value == "") {
        showMessageModal.value = true;
        setTimeout(() => {
          showMessageModal.value = false;
        }, 1500);
        msg.value = "请在输入框内填写举报理由";
        return;
      }

      const obj = {
        FBizType: 2,
        FRelationId: doMoreOptId.value,
        FBizAttributes: [],
        FReportLabels: [
          {
            FValue: checkTipId.value,
            FTitle: checkTipTitle.value,
          },
        ],
        FContent: FTipContent.value,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("tipOffCommon", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = "举报成功";
            FTipContent.value = "";
            showBoxModal.value = false;
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const toSubmitComment = () => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      const obj = {
        FType: 4,
        FReferCode: id.value,
        FirstId: 0,
        FReplyId: 0,
        FContent: commentInfo.value,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("CommentAdd", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            //    这里处理评论成功
            const commentObj = data.data[0];

            commentList.value.unshift(commentObj);
            const cl = commentList.value;
            cl.forEach((item) => {
              item.isShowRF = false;
              item.commentInfo = "";
            });
            cl[0].FReplyListAdd = [];
            cl[0].FReplyTotalNew = 0;
            cl[0].FReplyListFY = [];
            cl[0].FReplyList = [];
            cl[0].isShowFY = false;
            cl[0].isShowMore = false;
            cl[0].isShowPanel = false;
            cl[0].pageNum = 1;
            cl[0].page = 1;
            commentInfo.value = "";
            commentCount.value++;
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      const obj2 = {
        code: "W100089",
        rcode: "",
        dcode: "",
      };
      report(obj2);
    };

    // 添加父评论的评论
    const toSubmitFComment = (info, fid) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      const obj = {
        FType: 4,
        FReferCode: id.value,
        FirstId: fid,
        FReplyId: 0,
        FContent: info,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("CommentAdd", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            //    这里处理评论成功
            const commentObj = data.data[0];
            // commentList.value.unshift(commentObj);
            // commentInfo.value = ''
            // commentCount.value++

            // 不分页
            const cl = commentList.value;
            cl.forEach((element) => {
              if (element.FId == fid) {
                element.FReplyListAdd.push(commentObj);
                element.FReplyListAdd.forEach((item2) => {
                  item2.isShowRS = false;
                  item2.commentInfo = "";
                  item2.isShowFBB = false;
                });
                element.commentInfo = "";
                element.isShowRF = false;
                element.FReplyTotalNew++;
                element.pageNum = Math.ceil(element.FReplyTotalNew / 5);
                commentCount.value++;
              }
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      const obj2 = {
        code: "W100089",
        rcode: "",
        dcode: "",
      };
      report(obj2);
    };

    // 添加子评论的评论
    const toSubmitSComment = (info, sid, fid) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      const obj = {
        FType: 4,
        FReferCode: id.value,
        FirstId: fid,
        FReplyId: sid,
        FContent: info,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("CommentAdd", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            //    这里处理评论成功
            const commentObj = data.data[0];

            const cl = commentList.value;
            cl.forEach((element) => {
              if (element.FId == fid) {
                element.FReplyListAdd.push(commentObj);
                element.FReplyListAdd.forEach((element2) => {
                  element2.commentInfo = "";
                  element2.isShowRS = false;
                  element2.isShowFBB = false;
                });
                commentCount.value++;
                element.FReplyTotalNew++;
                element.pageNum = Math.ceil(element.FReplyTotalNew / 5);
                // 关闭不分页回复框
                if (!element.isShowFY) {
                  element.FReplyList.forEach((element2) => {
                    element2.commentInfo = "";
                    element2.isShowRS = false;
                  });
                }
                // 关闭分页回复框
                if (element.isShowFY) {
                  element.FReplyListFY.forEach((element2) => {
                    element2.commentInfo = "";
                    element2.isShowRS = false;
                  });
                }
              }
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      const obj2 = {
        code: "W100089",
        rcode: "",
        dcode: "",
      };
      report(obj2);
    };

    // 添加子评论暂存区的评论
    const toSubmitSComment2 = (info, sid, fid) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      const obj = {
        FType: 4,
        FReferCode: id.value,
        FirstId: fid,
        FReplyId: sid,
        FContent: info,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("CommentAdd", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            //    这里处理评论成功
            const commentObj = data.data[0];

            const cl = commentList.value;
            cl.forEach((element) => {
              if (element.FId == fid) {
                element.FReplyListAdd.push(commentObj);
                element.FReplyListAdd.forEach((element2) => {
                  element2.commentInfo = "";
                  element2.isShowRS = false;
                  element2.isShowFBB = false;
                });
                commentCount.value++;
                element.FReplyTotalNew++;
                element.pageNum = Math.ceil(element.FReplyTotalNew / 5);
              }
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      const obj2 = {
        code: "W100089",
        rcode: "",
        dcode: "",
      };
      report(obj2);
    };
    const isShowShareItem = ref(false);

    const articalInfo = ref<any>({});
    const articalUserInfo = ref({});
    const markFollowState = (status) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      const obj = {
        FType: 2,
        FRelationCode: articalUserInfo.value["FUserCode"],
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("focusSetup", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            if (status == 1) {
              articalUserInfo.value["FFansStatus"] = 2;
              articalUserInfo.value["FFansNum"]++;
            } else {
              articalUserInfo.value["FFansStatus"] = 1;
              articalUserInfo.value["FFansNum"]--;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const shareItem = () => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      isShowShareItem.value = !isShowShareItem.value;

      const obj = {
        code: "W100094",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };
    const commentDelet = () => {
      const obj = {
        FId: doMoreOptId.value,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("commentDelete", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            const cl = commentList.value;
            const index = cl.findIndex(
              (element) => element.FId === doMoreOptId.value
            );
            if (index !== -1) {
              cl.splice(index, 1);
            }
            showBoxModal.value = false;
            commentCount.value--;
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const markLikeState = (type, id, status, fid, sid) => {
      const obj = {
        FType: type,
        FReferCode: id,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("behaviorLike", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            // 文章
            if (type == 4) {
              if (status == 1) {
                articalInfo.value["FLikeCount"]++;
                articalInfo.value["FLikeStatus"] = 1;
                articalUserInfo.value["FLikeNum"]++;
              }
              if (status == 2) {
                articalInfo.value["FLikeCount"]--;
                articalInfo.value["FLikeStatus"] = 2;
                articalUserInfo.value["FLikeNum"]--;
              }
            }
            // 评论
            if (type == 3) {
              if (sid == 0) {
                // 父评论
                const cl = commentList.value;
                cl.forEach((element) => {
                  if (element.FId == fid) {
                    if (status == 1) {
                      element.FLikeStatus = 1;
                      element.FLikeCount++;
                    }
                    if (status == 2) {
                      element.FLikeStatus = 2;
                      element.FLikeCount--;
                    }
                  }
                });
              }
              if (sid != 0) {
                // 子评论
                const cl = commentList.value;
                cl.forEach((element) => {
                  if (element.FId == fid) {
                    if (!element.isShowFY) {
                      const cl2 = element.FReplyList;
                      cl2.forEach((element2) => {
                        if (element2.FId == sid) {
                          if (status == 1) {
                            element2.FLikeStatus = 1;
                            element2.FLikeCount++;
                          }
                          if (status == 2) {
                            element2.FLikeStatus = 2;
                            element2.FLikeCount--;
                          }
                        }
                      });
                    }

                    if (element.isShowFY) {
                      const cl2 = element.FReplyListFY;
                      cl2.forEach((element2) => {
                        if (element2.FId == sid) {
                          if (status == 1) {
                            element2.FLikeStatus = 1;
                            element2.FLikeCount++;
                          }
                          if (status == 2) {
                            element2.FLikeStatus = 2;
                            element2.FLikeCount--;
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    // 假评论区域处理
    const markLikeState2 = (type, id, status, fid, sid) => {
      const obj = {
        FType: type,
        FReferCode: id,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("behaviorLike", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            // 评论
            // 子评论
            const cl = commentList.value;
            cl.forEach((element) => {
              if (element.FId == fid) {
                const cl2 = element.FReplyListAdd;
                cl2.forEach((element2) => {
                  if (element2.FId == sid) {
                    if (status == 1) {
                      element2.FLikeStatus = 1;
                      element2.FLikeCount++;
                    }
                    if (status == 2) {
                      element2.FLikeStatus = 2;
                      element2.FLikeCount--;
                    }
                  }
                });
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const changeLikeStatus = (type) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      //  type:2取消喜欢 1喜欢

      markLikeState(4, id.value, type, 0, 0);
      if (type == 1) {
        const obj = {
          code: "W100090",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        const obj = {
          code: "W100091",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
    };
    const changeFCommentLikeStatus = (id, type) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      if (type == 1) {
        const obj = {
          code: "W100101",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        const obj = {
          code: "W100102",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      //  type:2取消喜欢 1喜欢
      markLikeState(3, id, type, id, 0);
    };
    const changeSCommentLikeStatus = (fid, sid, type) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      //  type:2取消喜欢 1喜欢
      markLikeState(3, sid, type, fid, sid);
    };
    const changeSCommentLikeStatus2 = (fid, sid, type) => {
      //  type:2取消喜欢 1喜欢
      markLikeState2(3, sid, type, fid, sid);
    };

    const markCollectState = (status) => {
      const obj = {
        FType: 4,
        FReferCode: id.value,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("behaviorCollect", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            if (status == 1) {
              articalInfo.value["FCollectCount"]++;
              articalInfo.value["FCollectStatus"] = 1;
            }
            if (status == 2) {
              articalInfo.value["FCollectCount"]--;
              articalInfo.value["FCollectStatus"] = 2;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const changeCollectStatus = (type) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }
      if (type == 1) {
        const obj = {
          code: "W100092",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        const obj = {
          code: "W100093",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      //  type:2取消喜欢 1喜欢

      markCollectState(type);
    };

    const closePreview = () => {
      showPreview.value = false;
    };
    const playVideo = (url) => {
      // window.location.href = videoUrl.value;
      const obj = {
        FUrl: url,
      };

      const paramStr = JSON.stringify(obj);
      store
        .dispatch("antiLeech", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            window.open(data.data.FUrl);
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const handleClick = (event) => {
      // 正常A标签
      if (event.target.tagName === "A") {
        event.preventDefault();
        const href = event.target.getAttribute("href");

        if (href.includes("yqwb")) {
          //    站外视频
          const jsonDataString = decodeURIComponent(href.split("://")[1]);
          try {
            const jsonData = JSON.parse(jsonDataString);
            // 图片
            if (jsonData.FType == 2) {
              playVideo(jsonData.FUrl);
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          window.open(href, "_blank");
        }
      }
      // img a标签
      if (event.target.tagName === "IMG") {
        event.preventDefault();
        const parentElement = event.target.parentElement;
        if (parentElement.tagName === "A") {
          const href = parentElement.getAttribute("href");

          // 解码URL
          const jsonDataString = decodeURIComponent(href.split("://")[1]);
          try {
            const jsonData = JSON.parse(jsonDataString);

            // 图片
            if (jsonData.FType == 1) {
              openPreview(jsonData.FUrl);
            }
            // 站内视频
            if (jsonData.FType == 2) {
              const url =
                _url.value +
                "/shareVideoWeb.html?id=" +
                jsonData.FVideoId +
                "&shareCode=5KU1YH";
              window.open(url, "_blank");
            }
          } catch (error) {
            console.error(error);
          }
          // 这里可以根据需求对a标签的点击事件进行其他操作
        }
      }
    };

    const changeSBg = (type) => {
      const elements = document.querySelectorAll(".share-item");
      elements.forEach((element) => {
        if (element && element.textContent === type) {
          element.classList.remove("BGF");
          element.classList.add("BGF6");
        }
      });
    };

    const changeSBg2 = (type) => {
      const elements = document.querySelectorAll(".share-item");
      elements.forEach((element) => {
        if (element && element.textContent === type) {
          element.classList.remove("BGF6");
          element.classList.add("BGF");
        }
      });
    };
    const getCommentReply = (page, cid) => {
      const obj = {
        FReferCode: id.value,
        FirstId: cid,
        FType: 4,
        FPage: page,
        FPageSize: 5,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("commentReply", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            const cl = commentList.value;
            cl.forEach((item) => {
              if (item.FId == cid) {
                item.FReplyListFY = data.data.FReply.FListData;
                item.FReplyListFY.forEach((item2) => {
                  item2.isShowRS = false;
                  item2.commentInfo = "";
                  item2.isShowFBB = false;
                });
                item.isShowFY = true;
              }
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      // FPage2.value += 1;
    };
    const getMoreComment = (fid) => {
      getCommentReply(1, fid);
      const cl = commentList.value;
      cl.forEach((element) => {
        if (element.FId == fid) {
          element.FReplyListAdd = [];
        }
      });
    };
    const shareArtical = () => {
      const obj = {
        FReferCode: id.value,
        FType: 16,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("shareArtical", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            articalInfo.value["FShareCount"]++;
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      FPage.value += 1;
    };

    const shareWeb = (type) => {
      // type: 1qq 2微信 3微博 4复制链接

      shareArtical();
      const url = ref("");
      if (type != 4) {
        url.value =
          _url.value + "/qrCode.html?type=" + type + "&FId=" + id.value;
        isShowShareItem.value = false;
        window.open(url.value);
        if (type == 1) {
          const obj = {
            code: "W100097",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
        if (type == 2) {
          const obj = {
            code: "W100095",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
        if (type == 3) {
          const obj = {
            code: "W100099",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
      }
      if (type == 4) {
        url.value = _url.value + "/articleDetail.html?FId=" + id.value;
        navigator.clipboard.writeText(url.value);
        isShowShareItem.value = false;
        showMessageModal.value = true;
        setTimeout(() => {
          showMessageModal.value = false;
        }, 1500);
        msg.value = "链接复制成功";

        const obj = {
          code: "W100100",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
    };

    const getArticalComment = (id) => {
      const obj = {
        FReferCode: id,
        FType: 4,
        FPage: FPage.value,
        FPageSize: FPageSize.value,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("richTextComment", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            if (data.data.FTotal == 0) {
              isMoreData.value = false;
              return;
            }
            if (FPage.value == 1) {
              commentList.value = data.data.FListData;
            } else {
              commentList.value = [
                ...commentList.value,
                ...data.data.FListData,
              ];
            }

            commentCount.value = data.data.FCommentCount;

            const commentListData = commentList.value;
            commentListData.forEach((element) => {
              element.isShowMore = false;
              element.isShowPanel = false;
              element.isShowRF = false;
              element.commentInfo = "";
              element.FReplyTotalNew = element.FReplyTotal;
              element.page = 1;
              element.pageNum = Math.ceil(element.FReplyTotal / 5);
              element.isShowFY = false;
              element.FReplyListAdd = [];
              element.isShowFBB = false;
              element.FReplyListFY = [];
              if (element.FReplyList.length != 0) {
                element.FReplyList.forEach((element2) => {
                  element2.isShowRS = false;
                  element2.commentInfo = "";
                  element2.isShowFBB = false;
                });
              }
            });

            FPage.value += 1;
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const commentTop = (status) => {
      const obj = {
        FId: doMoreOptId.value,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("commentTop", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            FPage.value = 1;
            getArticalComment(id.value);
            if (status == 1) {
              showBoxModal.value = false;
            }
            if (status == 2) {
              showMessageModal.value = true;
              setTimeout(() => {
                showMessageModal.value = false;
              }, 1500);
              msg.value = "已取消置顶";
            }
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const commentOpt = (type) => {
      // type 1:置顶 2：举报 3：删除 4：取消置顶

      if (type != 4) {
        showBoxModal.value = true;
        showBoxModalType.value = type;
        if (type == 1) {
          getTip();
          const obj = {
            code: "W100105",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
        if (type == 3) {
          getTip();
          const obj = {
            code: "W100106",
            rcode: "",
            dcode: "",
          };
          report(obj);
        }
      }
      if (type == 2) {
        getTip();
        const obj = {
          code: "W100107",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      if (type == 4) {
        // getTip()
        commentTop(2);
        const obj = {
          code: "W100108",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      const cl = commentList.value;
      cl.forEach((element) => {
        element.isShowPanel = false;
      });
    };
    const { getArticalCommentList } = toRefs({
      getArticalCommentList: throttle(() => {
        if (isMoreData.value) {
          getArticalComment(id.value);
        }
      }, 3000),
    });
    const setTdk = () => {
      document.title = articalInfo.value.FTitle

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if(metaKeywords){
        const content = articalInfo.value.FKeyWord || articalInfo.value.FTitle;
        metaKeywords.setAttribute('content', content);
      }
      let metaDescription = document.querySelector('meta[name="description"]');
      if(metaDescription){
        const content = articalInfo.value.FOutline || articalInfo.value.FContentBrief.substring(0,100);
        metaDescription.setAttribute('content', content);
      }
    };
    const getArticalDetail = (id) => {
      const obj = {
        FId: id,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("richTextDetail", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            articalInfo.value = { ...data.data };
            articalUserInfo.value = data.data.FUser;
            FGamesInfo.value = data.data.FGames[0];
            setTdk()
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const infoItem = ref<HTMLElement | null>(null);
    const showScrollTopButton = ref(false);

    const handleScroll = () => {
      if (infoItem.value) {
        if (infoItem.value.scrollTop > infoItem.value.clientHeight * 3) {
          showScrollTopButton.value = true;
        } else {
          showScrollTopButton.value = false;
        }
      }
    };

    const scrollTop = () => {
      if (infoItem.value) {
        infoItem.value.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    const changeBgColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.background = "#e5e5e5";
    };

    const removeBgColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.background = "#fff";
    };

    const changeTextColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#666";
    };

    const removeTextColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#999";
    };
    const isShowRF = ref(false);
    const isShowRS = ref(false);
    const replySComment = (fid, sid) => {
      const commentListData = commentList.value;
      commentListData.forEach((element) => {
        if (element.FId == fid) {
          // 不分页
          if (!element.isShowFY) {
            element.FReplyList.forEach((element2) => {
              if (element2.FId == sid) element2.isShowRS = !element2.isShowRS;
            });
          }

          // 分页
          if (element.isShowFY) {
            element.FReplyListFY.forEach((element2) => {
              if (element2.FId == sid) element2.isShowRS = !element2.isShowRS;
            });
          }
        }
      });
    };

    // 子评论暂存区
    const replySComment2 = (fid, sid) => {
      const commentListData = commentList.value;
      commentListData.forEach((element) => {
        if (element.FId == fid) {
          element.FReplyListAdd.forEach((element2) => {
            if (element2.FId == sid) element2.isShowRS = !element2.isShowRS;
          });
        }
      });
    };
    const replyFComment = (id) => {
      // isShowRF.value = !isShowRF.value
      const commentListData = commentList.value;
      commentListData.forEach((element) => {
        if (element.FId == id) {
          element.isShowRF = !element.isShowRF;
        }
      });
    };
    const changePage = (num, fid) => {
      const commentListData = commentList.value;
      commentListData.forEach((element) => {
        if (element.FId == fid) {
          element.page = num;
          element.FReplyListAdd = [];
        }
      });
      getCommentReply(num, fid);
    };

    const handleCloseModal = ($event) => {
      // 分享
      if (!$event.target.closest(".share-item-panel")) {
        isShowShareItem.value = false;
      }
      // 评论处理
      if (!$event.target.closest(".comment-handle-panel")) {
        const commentListData = commentList.value;
        commentListData.forEach((element) => {
          element.isShowPanel = false;
        });
      }
    };

    const router = useRouter();

    const toSearchDetail = (info) => {
      const obj = {
        code: "W100057",
        rcode: "",
        dcode: "",
      };
      report(obj);
      if (isLogin.value) {
        if (localStorage.getItem("searchInfo")) {
          const originalSearchInfo = localStorage.getItem("searchInfo");
          const originalSearchInfoArray = originalSearchInfo
            ? originalSearchInfo.split(",")
            : [];
          if (!originalSearchInfoArray.includes(info)) {
            originalSearchInfoArray.push(info);
          }
          const newSearchInfo = originalSearchInfoArray.join(",");
          localStorage.setItem("searchInfo", newSearchInfo);
        } else {
          const newSearchInfo = info;
          localStorage.setItem("searchInfo", newSearchInfo);
        }
        const sarr = localStorage.getItem("searchInfo");
      }

      // router.push({ path: "/searchDetail", query: { info: info } });
      router.push({ path: `/searchDetail/${info}` });
      store.commit("setIsSearch", true);
    };
    
    onMounted(() => {
      if (
        window.location.href.indexOf("oa") > -1 ||
        window.location.href.includes("localhost")
      ) {
        _url.value = "https://oaweb.7ugame.cn";
      }
      const route = useRoute();
      // if (route.query.id) {
      //   console.log('route.query.id',route.query.id)
      id.value = route.params.id;
      getArticalDetail(route.params.id);
      getArticalComment(route.params.id);
      // }
      if (route.params.type == "1") {
        const commentTextarea = document.querySelector(
          ".commentTextarea"
        ) as HTMLElement;
        if (commentTextarea) {
          commentTextarea.focus();
        }
        setTimeout(() => {
          const commentElement = document.querySelector(
            ".comment"
          ) as HTMLElement;
          console.log("commentElement", commentElement);
          if (commentElement) {
            console.log("commentElement2", commentElement);
            commentElement.scrollIntoView({ behavior: "smooth" });
          }
        }, 1000);
      }

      const element = document.querySelector(".info-item") as HTMLElement;
      infoItem.value = element;
      infoItem.value.addEventListener("scroll", handleScroll);
      const main = document.querySelector(".main") as HTMLElement;

      main.addEventListener("wheel", (event) => {
        element.scrollTop += event.deltaY;
        event.preventDefault();
        const distanceToBottom =
          element.scrollHeight - (element.scrollTop + element.clientHeight);
        if (distanceToBottom <= 100) {
          getArticalCommentList.value();
        }
      });

      setTimeout(()=>{
        const briefDetail = document.querySelector(
          ".brief-detail"
        ) as HTMLElement;
        if (
          briefDetail.scrollWidth > briefDetail.clientWidth ||
          briefDetail.offsetWidth < briefDetail.scrollWidth
        ) {
          briefDetailOver.value = true;
        } else {
          briefDetailOver.value = false;
        }
      },200)
    });

    return {
      briefDetailOver,
      toSearchDetail,
      isShowFBB,
      handleCloseModal,
      changeSCommentLikeStatus2,
      changePage,
      isShowRF,
      isShowRS,
      replySComment,
      replySComment2,
      replyFComment,
      changeTextColor,
      removeTextColor,
      changeBgColor,
      removeBgColor,
      FGamesInfo,
      showScrollTopButton,
      scrollTop,
      submitTip,
      FTipContent,
      tipList,
      getTip,
      checkTip,
      checkTipId,
      checkTipTitle,
      commentTop,
      commentDelet,
      userCode,
      doMoreOpt,
      doMoreOptId,
      commentOpt,
      showBoxModalType,
      showBoxModal,
      toSubmitComment,
      toSubmitFComment,
      toSubmitSComment,
      toSubmitSComment2,
      commentCount,
      shareArtical,
      commentInfo,
      markCollectState,
      changeCollectStatus,
      changeLikeStatus,
      changeFCommentLikeStatus,
      changeSCommentLikeStatus,
      markLikeState,
      handleLoginSuccess,
      showLoginModal,
      getCommentReply,
      getMoreComment,
      getArticalCommentList,
      commentList,
      id,
      FPage,
      FPageSize,
      getArticalDetail,
      getArticalComment,
      articalInfo,
      articalUserInfo,
      markFollowState,
      msg,
      showMessageModal,
      shareWeb,
      changeSBg,
      changeSBg2,
      isShowShareItem,
      shareItem,
      imageUrl,
      showPreview,
      openPreview,
      closePreview,
      playVideo,
      videoUrl,
      handleClick,
      richTextContent,
      showBriefDetail,
    };
  },
});
