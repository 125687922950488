
import { defineComponent, ref, onMounted } from 'vue';
import { useSkeleton } from 'skeletor';

interface Item {
    id: number;
    name: string;
}

export default defineComponent({
    name: 'SkeletonDemo',
    setup() {
        const loading = ref(true);
        const items = ref<Item[]>([]);

        onMounted(() => {
            // 模拟异步数据加载
            setTimeout(() => {
                items.value = [
                    { id: 1, name: 'Item 1' },
                    { id: 2, name: 'Item 2' },
                    { id: 3, name: 'Item 3' },
                ];
                loading.value = false;
            }, 2000);
        });

        // 使用 useSkeleton 函数生成骨架屏效果
        // useSkeleton({
        //     loading,
        //     items,
        // });

        return {
            loading,
            items,
        };
    },
});
