
// 节流函数
const throttle = (func: Function, delay: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    func(...args);
  };
};
import Message from "../components/Message.vue";
import creatwMessage from "../components/createMessage";
import { useRoute } from "vue-router";
import {
  defineComponent,
  computed,
  onUpdated,
  onMounted,
  watch,
  ref,
  toRefs,
  nextTick,
} from "vue";
import "../css/base.css";
import { useStore } from "vuex";

import { GlobalDataProps } from "../store";
import BasicInfo from "../components/BasicInfo.vue";

export default defineComponent({
  name: "ToolDetail",
  components: {
    Message,
    BasicInfo,
  },
  // mounted() {

  //     const infoItem = document.querySelector('.info-item') as HTMLElement;

  //     infoItem.addEventListener('scroll', () => {
  //         const distanceToBottom = infoItem.scrollHeight - infoItem.scrollTop - infoItem.clientHeight;
  //         if (distanceToBottom <= 100) {
  //             this.getToolListBottom();
  //         }
  //     });

  // },
  setup() {
    const _url = ref("https://web.7ugame.cn");
    const store = useStore<GlobalDataProps>();
    const report = (dataObj) => {
      const obj = {
        FReportType: 2,
        FEventSN: dataObj.code,
        FRelatedCode: dataObj.rcode,
        FRelatedDeviceCode: dataObj.dcode,
      };
      store
        .dispatch("point", { data: obj })
        .then((data) => {
          if (data.code != 0) {
            console.log(data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const toolItemDetail = ref({});
    const aboutToolList = ref<any[]>([]);
    const showBoxModal = ref(false);
    const showBoxModalType = ref(0);
    const paramArr = ref<any[]>([]);
    const paramKey = ref("");
    const isShowClassify = ref(false);
    const id = ref();
    const gameCode = ref();
    const userCode = localStorage.getItem("userCode");
    const gameName = ref();
    const isShowChangeGame = ref(false);
    const isMoreData = ref(true);
    const msg = ref("");
    const FPage = ref(1);
    const FPageSize = ref(40);
    const error = computed(() => store.state.error);
    const isLogin = computed(() => store.state.user.isLogin);
    const showMessageModal = ref(false);
    const categoriesList = ref<any[]>([]);
    const toolList = ref<any[]>([]);
    const categoriesName = ref();
    const getCategories = () => {
      const obj = {
        FGameCode: gameCode.value,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("gameToolCategories", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            const cl = data.data.FData;
            cl.forEach((element) => {
              if (element.FId == id.value) {
                categoriesList.value = element.FEntries;

                const cl = categoriesList.value;
                cl.forEach((element) => {
                  element.checkName = "";
                });
                categoriesName.value = element.FName;
                console.log("categoriesList", categoriesList.value);
              }
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const toVideo = (id) => {
      const url =
        _url.value+"/shareVideoWeb.html?id=" +
        id +
        "&shareCode=5KU1YH";
      window.open(url, "_blank");
    };

    const getToolList = (key, arr) => {
      const obj = {
        FGameCode: gameCode.value,
        FPage: FPage.value,
        FPageSize: FPageSize.value,
        FCategoryId: id.value,
        FKeyWord: key,
        FEntry: arr,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("gameToolList", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            if (data.data.FListData.length == 0) {
              isMoreData.value = false;
              return;
            }
            toolList.value = [...toolList.value, ...data.data.FListData];
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      FPage.value++;
    };
    const getToolDetail = (fid) => {
      const obj = {
        FGuideId: fid,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("gameToolDetail", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            toolItemDetail.value = data.data.FDetail;
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
      FPage.value++;
    };

    const openAbout = (fid, type) => {
      showBoxModal.value = true;
      showBoxModalType.value = type;
      if (type == 2) {
        const tl = toolList.value;
        tl.forEach((element) => {
          if (element.FId == fid) {
            aboutToolList.value = element.FRelationGuides;
          }
        });
      }
      if (type == 1) {
        getToolDetail(fid);
        const obj = {
          code: "W100122",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
    };

    const { getToolListBottom } = toRefs({
      getToolListBottom: throttle(() => {
        if (isMoreData.value) {
          getToolList(paramKey.value, paramArr.value);
        }
      }, 3000),
    });
    const searchList = () => {
      FPage.value = 1;
      toolList.value = [];
      isMoreData.value = true;
      getToolList(paramKey.value, paramArr.value);
      const obj = {
        code: "W100120",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };
    const clearKey = () => {
      FPage.value = 1;
      toolList.value = [];
      isMoreData.value = true;
      paramKey.value = "";
      getToolList(paramKey.value, paramArr.value);
    };
const isCheck = ref(false)
    const changeCheckName = (name, fid) => {
      const arr = ref<any[]>([]);
      const cl = categoriesList.value;
      cl.forEach((element) => {
        if (element.FId == fid) {
          if (element.checkName == name) {
            element.checkName = "";
            isCheck.value = false
          } else {
            element.checkName = name;
            isCheck.value = true
          }
        }
      });

      cl.forEach((element) => {
        if (element.checkName !== "") {
          const obj = {
            FEntryId: element.FId,
            FEntryValue: element.checkName,
          };

          arr.value.push(obj);
        }
      });
      paramArr.value = arr.value;
      FPage.value = 1;
      toolList.value = [];
      isMoreData.value = true;
      getToolList(paramKey.value, paramArr.value);
    };

    const infoItem = ref<HTMLElement | null>(null);
    const showScrollTopButton = ref(false);

    const handleScroll = () => {
      if (infoItem.value) {
        if (infoItem.value.scrollTop > infoItem.value.clientHeight * 3) {
          showScrollTopButton.value = true;
        } else {
          showScrollTopButton.value = false;
        }

        const distanceToBottom =
          infoItem.value.scrollHeight -
          infoItem.value.scrollTop -
          infoItem.value.clientHeight;
        if (distanceToBottom <= 100) {
          getToolListBottom.value();
        }
      }
    };

    const scrollTop = () => {
      if (infoItem.value) {
        infoItem.value.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    const handleCloseModal = ($event) => {
      // 工具
      if (!$event.target.closest('.classify-panel')) {
        isShowClassify.value = false
      }
  
    }


    onMounted(() => {
      if (
        window.location.href.indexOf("oa") > -1 ||
        window.location.href.includes("localhost")
      ) {
        _url.value = "https://oaweb.7ugame.cn";
      }

      const route = useRoute();
      // if (route.query.id) {
      console.log("route.params", route.params);
      id.value = route.params.id;
      gameCode.value = route.params.code;
      getCategories();
      getToolList("", {});
      // }

      const element = document.querySelector(".info-item") as HTMLElement;
      infoItem.value = element;
      infoItem.value.addEventListener("scroll", handleScroll);
    });

    return {
      isCheck,
      handleCloseModal,
      showScrollTopButton,
      scrollTop,
      clearKey,
      searchList,
      paramKey,
      paramArr,
      changeCheckName,
      toVideo,
      getToolDetail,
      toolItemDetail,
      openAbout,
      aboutToolList,
      showBoxModal,
      showBoxModalType,
      isMoreData,
      toolList,
      isShowClassify,
      categoriesName,
      categoriesList,
      getCategories,
      getToolListBottom,
      getToolList,
      showMessageModal,
      gameName,
      isShowChangeGame,
      userCode,
      FPage,
      FPageSize,
      msg,
      isLogin,
      error,
    };
  },
});
