
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  name: "Message",
  props: {
    showMessageModal: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "",
    },
  },

  setup(props) {
   

    return {
      // showMessageModal,
      // msg:props.msg,
    };
  },
});
