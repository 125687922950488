

// 节流函数
const throttle = (func: Function, delay: number) => {
    let lastCall = 0;
    return (...args: any[]) => {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        func(...args);
    };
};
import Message from "../components/Message.vue";
import creatwMessage from "../components/createMessage";
import { useRoute } from "vue-router";
import { defineComponent, computed, onUpdated, onMounted, watch, ref, toRefs, nextTick, inject } from "vue";
import '../css/base.css'
import { useStore } from "vuex";

import { GlobalDataProps } from "../store";
import BasicInfo from "../components/BasicInfo.vue";

export default defineComponent({
    name: "InfoDetail",
    components: {
        Message,
        BasicInfo
    },
    // mounted() {
    //     const infoItem = document.querySelector('.info-item') as HTMLElement;

    //     infoItem.addEventListener('scroll', () => {
    //         const distanceToBottom = infoItem.scrollHeight - infoItem.scrollTop - infoItem.clientHeight;
    //         if (distanceToBottom <= 100) {
    //             if (this.currentTab == 'message') {
    //                 this.getCommentList()
    //             }
    //             if (this.currentTab == 'like') {
    //                 this.getLikeCollectList()
    //             }
    //             if (this.currentTab == 'user') {
    //                 this.getFocusMessageList()
    //             }
    //             if (this.currentTab == 'info') {
    //                 this.getsystemMessageList()
    //             }
    //         }
    //     });

    // },
    setup() {
        const store = useStore<GlobalDataProps>();
        const report = (dataObj) => {
            const obj = {
                FReportType: 2,
                FEventSN: dataObj.code,
                FRelatedCode: dataObj.rcode,
                FRelatedDeviceCode: dataObj.dcode
            };
            store.dispatch("point", { data: obj })
                .then((data) => {
                    if (data.code != 0) {
                        console.log(data.message)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        const currentTab = ref('message')
        const msg = ref('');
        const FPage = ref(1);
        const FPageSize = ref(40);
        const error = computed(() => store.state.error);
        const isLogin = computed(() => store.state.user.isLogin);
        const showMessageModal = ref(false);
        const isCMoreData = ref(true)
        const isUMoreData = ref(true)
        const isSMoreData = ref(true)
        const isLMoreData = ref(true)
        const CFPage = ref(1)
        const LFPage = ref(1)
        const UFPage = ref(1)
        const SFPage = ref(1)
        const FSysNum = ref(0);
        const FSysArr = ref<any[]>([]);
        const FCommentArr = ref<any[]>([]);
        const FLikeCollectNum = ref(0);
        const FLikeCollectArr = ref<any[]>([]);
        const FFocusNum = ref(0);
        const FFocusArr = ref<any[]>([]);
        const hoverIndex = ref('')


        const setHoverBackground = (index) => {
            hoverIndex.value = index
        }

        const resetHoverBackground = () => {
            hoverIndex.value = ''
        }

        const getUnReads = () => {
            const obj = {

            };
            const paramStr = JSON.stringify(obj);
            store.dispatch("unReads", { data: paramStr })
                .then((data) => {
                    if (data.code == 0) {
                        FSysNum.value = data.data.FSysNum
                        FLikeCollectNum.value = data.data.FLikeCollectNum
                        FFocusNum.value = data.data.FFocusNum
                    } else {
                        showMessageModal.value = true;
                        setTimeout(() => {
                            showMessageModal.value = false;
                        }, 1500);
                        msg.value = data.message
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        // 评论
        const getComment = () => {
            const obj = {
                FType: 1,
                FPage: CFPage.value,
                FPageSize: 20
            };
            store.commit("setMessage", true);
            const paramStr = JSON.stringify(obj);
            store.dispatch("comment", { data: paramStr })
                .then((data) => {
                    if (data.code == 0) {
                        if (data.data.FListData.length == 0) {
                            isCMoreData.value = false
                        }
                        FCommentArr.value = [...FCommentArr.value, ...data.data.FListData]
                    } else {
                        showMessageModal.value = true;
                        setTimeout(() => {
                            showMessageModal.value = false;
                        }, 1500);
                        msg.value = data.message
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            CFPage.value++
        }

        const { getCommentList } = toRefs({

            getCommentList: throttle(() => {
                if (isCMoreData.value) {
                    getComment();
                }
            }, 3000)

        });

        // 点赞
        const getLikeCollect = () => {
            const obj = {

                FPage: LFPage.value,
                FPageSize: 20
            };
            store.commit("setLike", true);
            const paramStr = JSON.stringify(obj);
            store.dispatch("likeCollect", { data: paramStr })
                .then((data) => {
                    if (data.code == 0) {
                        if (data.data.FListData.length == 0) {
                            isLMoreData.value = false
                        }
                        FLikeCollectArr.value = [...FLikeCollectArr.value, ...data.data.FListData]
                    } else {
                        showMessageModal.value = true;
                        setTimeout(() => {
                            showMessageModal.value = false;
                        }, 1500);
                        msg.value = data.message
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            LFPage.value++
        }

        const { getLikeCollectList } = toRefs({

            getLikeCollectList: throttle(() => {
                if (isLMoreData.value) {
                    getLikeCollect();
                }
            }, 3000)

        });

        // 关注
        const getFocusMessage = () => {
            const obj = {

                FPage: UFPage.value,
                FPageSize: 20
            };
            store.commit("setUser", true);
            const paramStr = JSON.stringify(obj);
            store.dispatch("focusMessageList", { data: paramStr })
                .then((data) => {
                    if (data.code == 0) {
                        if (data.data.FListData.length == 0) {
                            isUMoreData.value = false
                        }
                        FFocusArr.value = [...FFocusArr.value, ...data.data.FListData]
                    } else {
                        showMessageModal.value = true;
                        setTimeout(() => {
                            showMessageModal.value = false;
                        }, 1500);
                        msg.value = data.message
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            UFPage.value++
        }

        const { getFocusMessageList } = toRefs({

            getFocusMessageList: throttle(() => {
                if (isUMoreData.value) {
                    getFocusMessage();
                }
            }, 3000)

        });

        // 系统
        const getsystemMessage = () => {
            const obj = {

                FPage: SFPage.value,
                FPageSize: 20
            };
            store.commit("setInfo", true);
            const paramStr = JSON.stringify(obj);
            store.dispatch("systemMessageList", { data: paramStr })
                .then((data) => {
                    if (data.code == 0) {
                        if (data.data.FListData.length == 0) {
                            isSMoreData.value = false
                        }
                        FSysArr.value = [...FSysArr.value, ...data.data.FListData]
                    } else {
                        showMessageModal.value = true;
                        setTimeout(() => {
                            showMessageModal.value = false;
                        }, 1500);
                        msg.value = data.message
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            SFPage.value++
        }

        const { getsystemMessageList } = toRefs({

            getsystemMessageList: throttle(() => {
                if (isSMoreData.value) {
                    getsystemMessage();
                }
            }, 3000)

        });

        const changeTab = (tab) => {
            if (currentTab.value != tab) {
                if (tab == 'message') {
                    getComment()
                    const obj = {
                        code: 'W100123',
                        rcode: '',
                        dcode: ''
                    }
                    report(obj)
                }
                if (tab == 'user') {
                    getFocusMessage()
                    FFocusNum.value = 0
                    const obj = {
                        code: 'W100125',
                        rcode: '',
                        dcode: ''
                    }
                    report(obj)
                }
                if (tab == 'like') {
                    FLikeCollectNum.value = 0
                    getLikeCollect()
                    const obj = {
                        code: 'W100124',
                        rcode: '',
                        dcode: ''
                    }
                    report(obj)
                }
                if (tab == 'info') {
                    FSysNum.value = 0
                    getsystemMessage()
                    const obj = {
                        code: 'W100126',
                        rcode: '',
                        dcode: ''
                    }
                    report(obj)
                }
            }
            currentTab.value = tab;
            store.commit('setFTotalNum', FSysNum.value + FFocusNum.value + FLikeCollectNum.value);

        }
        // 一键已读
        const clearAll = () => {
            const obj = {

            };
            const paramStr = JSON.stringify(obj);
            store.dispatch("clearAll", { data: paramStr })
                .then((data) => {
                    if (data.code != 0) {
                        showMessageModal.value = true;
                        setTimeout(() => {
                            showMessageModal.value = false;
                        }, 1500);
                        msg.value = data.message
                    }
                })
                .catch((e) => {
                    console.log(e);
                });

            const obj2 = {
                code: 'W100127',
                rcode: '',
                dcode: ''
            }
            report(obj2)

        }

        const readAll = () => {
            FSysNum.value = 0
            FLikeCollectNum.value = 0
            FFocusNum.value = 0
            store.commit('setFTotalNum', 0);
            clearAll()

        }

        onMounted(() => {

            const route = useRoute();
            if (route.params.type) {
                if (typeof route.params.type === 'string' || route.params.type === null) {
                    currentTab.value = route.params.type as string;
                    const tab = currentTab.value
                    if (tab == 'message') {
                        getComment()
                    }
                    if (tab == 'user') {
                        getFocusMessage()
                    }
                    if (tab == 'like') {
                        getLikeCollect()
                    }
                    if (tab == 'info') {
                        getsystemMessage()
                    }
                }

            }

            const infoItem = document.querySelector('.info-item') as HTMLElement;

            infoItem.addEventListener('scroll', () => {
                const distanceToBottom = infoItem.scrollHeight - infoItem.scrollTop - infoItem.clientHeight;
                if (distanceToBottom <= 100) {
                    if (currentTab.value == 'message') {
                        getCommentList.value()
                    }
                    if (currentTab.value == 'like') {
                        getLikeCollectList.value()
                    }
                    if (currentTab.value == 'user') {
                        getFocusMessageList.value()
                    }
                    if (currentTab.value == 'info') {
                        getsystemMessageList.value()
                    }
                }
            });


        });


        return {
            getCommentList,
            getsystemMessageList,
            getFocusMessageList,
            getLikeCollectList,
            readAll,
            getUnReads,
            CFPage,
            LFPage,
            UFPage,
            SFPage,
            hoverIndex,
            setHoverBackground,
            resetHoverBackground,
            FFocusNum,
            FLikeCollectNum,
            FSysNum,
            FCommentArr,
            FSysArr,
            FLikeCollectArr,
            FFocusArr,
            currentTab,
            changeTab,
            isCMoreData,
            isLMoreData,
            isSMoreData,
            isUMoreData,
            FPage,
            FPageSize,
            msg,
            showMessageModal,
            isLogin,
            error,
        };

    },

});
