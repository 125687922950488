
declare global {
  interface Window {
    TencentCaptcha: any;
  }
}
import { defineComponent, ref, onMounted, reactive, watch } from "vue";
// import createMessage from "./createMessage";
// import { TencentCaptcha } from "@/TCaptcha.js";
import Message from "./Message.vue";
// console.log('-----TencentCaptcha-----', TencentCaptcha)

// import { API_URL, API_ENDPOINTS } from '@/api.js';
// const url = `${API_URL}${API_ENDPOINTS.LOGIN}`;
// console.log('---------url---------',url)

// import ValidateInput, { RulesProp } from "../components/ValidateInput.vue";
import QRCode from "qrcode";
import axios from 'axios';
import { useStore } from "vuex";

export default defineComponent({
  name: "Login",
  props: {
    showLoginModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Message,
  },

  setup(props, { emit }) {
    const _url = ref("https://web.7ugame.cn");
    const store = useStore();
    const report = (dataObj) => {
      const obj = {
        FReportType: 2,
        FEventSN: dataObj.code,
        FRelatedCode: dataObj.rcode,
        FRelatedDeviceCode: dataObj.dcode,
      };
      store
        .dispatch("point", { data: obj })
        .then((data) => {
          if (data.code != 0) {
            console.log(data.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const toyszc = () => {
      window.open(_url.value + "/privacyls-web.html");
      const obj = {
        code: "W100022",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };

    const toyhxy = () => {
      window.open(_url.value + "/protocolls-web.html");
      const obj = {
        code: "W100021",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };
    const smsLogin = ref(true);
    const payload = reactive({}); //响应式对象
    const passwordInput = ref<HTMLInputElement | null>(null);
    const passwordInput2 = ref<HTMLInputElement | null>(null);
    const passwordInput3 = ref<HTMLInputElement | null>(null);
    const isPasswordVisible = ref(false);
    const isPasswordVisible2 = ref(false);
    const isPasswordVisible3 = ref(false);

    const isShowCount = ref(false);
    const isShowCount2 = ref(false);
    const buttonText = ref("重新发送：60s");
    const buttonText2 = ref("重新发送：60s");
    const showMessageModal = ref(false);
    const isChangePassword = ref(false);
    const isAppScan = ref(false); // 是否为app扫码登录
    const closeLoginModal = () => {
      isChangePassword.value = false;
      smsLogin.value = true;
      isAppScan.value = false;
      emit("closeLoginModal");
      const obj = {
        code: "W100020",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };
    const msg = ref("haha"); // 定义消息内容

    const isHover = ref(false);
    // const store = useStore();
    const phoneNumber = ref("");
    const phoneNumber2 = ref("");
    const passwordNumber = ref("");
    const codeNumber = ref("");
    const codeNumber2 = ref("");
    const newPassword = ref("");
    const newPassword2 = ref("");
    const isSendMsg = ref(false);

    const isPhoneNumberValid = ref(false);
    const isPhoneNumberValid2 = ref(false);
    const isCodeNumberValid2 = ref(false);
    const isCodeNumberValid = ref(false);
    const isPasswordValid = ref(false);
    const isPasswordValid2 = ref(false);
    const isPasswordValid3 = ref(false);
    const isChangePasswordSubmit = ref(false);

    const validatePhone = () => {
      const phoneReg = /^1[3456789]\d{9}$/;
      isPhoneNumberValid.value = phoneReg.test(phoneNumber.value);
    };
    const validatePhone2 = () => {
      const phoneReg = /^1[3456789]\d{9}$/;
      isChangePasswordSubmit.value = false;
      isPhoneNumberValid2.value = phoneReg.test(phoneNumber2.value);
      if (
        isPhoneNumberValid2.value &&
        isPasswordValid.value &&
        isPasswordValid2.value &&
        isPasswordValid3.value
      ) {
        isSendMsg.value = true;
        if (
          isCodeNumberValid2.value &&
          isSendMsg.value &&
          isPhoneNumberValid2.value
        ) {
          isChangePasswordSubmit.value = true;
        }
      } else {
        isSendMsg.value = false;
      }
    };

    const returnLogin = () => {
      isChangePassword.value = false;
      phoneNumber2.value = "";
      newPassword.value = "";
      newPassword2.value = "";
      codeNumber2.value = "";
      const obj = {
        code: "W100017",
        rcode: "",
        dcode: "",
      };
      report(obj);
    };
    const toChangePassword = () => {
      isChangePassword.value = true;
      phoneNumber.value = "";
      codeNumber.value = "";
      passwordNumber.value = "";
    };
    const validateCode = () => {
      const codeReg = /^\d{6}$/;
      isCodeNumberValid.value = codeReg.test(codeNumber.value);
    };
    const validatePassword = () => {
      isChangePasswordSubmit.value = false;
      const codeReg = /^[\w\W]{8,30}$/;
      const codeReg2 =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d])|(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z\d])|(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,30}$/;
      isPasswordValid.value = codeReg.test(newPassword.value);
      isPasswordValid2.value = codeReg2.test(newPassword.value);
      if (newPassword.value == newPassword2.value) {
        isPasswordValid3.value = true;
      } else {
        isPasswordValid3.value = false;
      }
      if (
        isPhoneNumberValid2.value &&
        isPasswordValid.value &&
        isPasswordValid2.value &&
        isPasswordValid3.value
      ) {
        isSendMsg.value = true;
        if (
          isCodeNumberValid2.value &&
          isSendMsg.value &&
          isPhoneNumberValid2.value
        ) {
          isChangePasswordSubmit.value = true;
        }
      } else {
        isSendMsg.value = false;
      }
    };

    const validatePassword2 = () => {
      isChangePasswordSubmit.value = false;
      if (newPassword.value == newPassword2.value) {
        isPasswordValid3.value = true;
      } else {
        isPasswordValid3.value = false;
      }
      if (
        isPhoneNumberValid2.value &&
        isPasswordValid.value &&
        isPasswordValid2.value &&
        isPasswordValid3.value
      ) {
        isSendMsg.value = true;
        if (
          isCodeNumberValid2.value &&
          isSendMsg.value &&
          isPhoneNumberValid2.value
        ) {
          isChangePasswordSubmit.value = true;
        }
      } else {
        isSendMsg.value = false;
      }
    };

    const validateCode2 = () => {
      isChangePasswordSubmit.value = false;
      const codeReg = /^\d{6}$/;
      isCodeNumberValid2.value = codeReg.test(codeNumber2.value);
      if (
        isCodeNumberValid2.value &&
        isSendMsg.value &&
        isPhoneNumberValid2.value
      ) {
        isChangePasswordSubmit.value = true;
      }
    };

    const togglePasswordVisibility = () => {
      isPasswordVisible.value = !isPasswordVisible.value;

      if (passwordInput.value) {
        passwordInput.value.type = isPasswordVisible.value
          ? "text"
          : "password";
      }
    };

    const togglePasswordVisibility2 = () => {
      isPasswordVisible2.value = !isPasswordVisible2.value;

      if (passwordInput2.value) {
        passwordInput2.value.type = isPasswordVisible2.value
          ? "text"
          : "password";
      }
    };
    const togglePasswordVisibility3 = () => {
      isPasswordVisible3.value = !isPasswordVisible3.value;

      if (passwordInput3.value) {
        passwordInput3.value.type = isPasswordVisible3.value
          ? "text"
          : "password";
      }
    };
    // 获取验证码
    const getCode = (str,ticket) => {
      const payload1 = {
        phone: phoneNumber.value,
        tradeType: "regCaptcha",
        FTicket:ticket,
        FRandStr:str,
      };
      const payload2 = {
        phone: phoneNumber2.value,
        tradeType: "pwdCatpcha",
        FTicket:ticket,
        FRandStr:str,
      };
      if (!isChangePassword.value) {
        Object.assign(payload, payload1);
        const obj = {
          code: "W100011",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        Object.assign(payload, payload2);
        const obj = {
          code: "W100015",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
      store
        .dispatch("sentCaptcha", { data: payload })
        .then((data) => {
          if (data.errno === 0) {
            if (!isChangePassword.value) {
              let count = 60;
              isShowCount.value = true;
              const timer = setInterval(() => {
                count--;
                if (count === 0) {
                  clearInterval(timer);
                  buttonText.value = "";
                  isShowCount.value = false;
                } else {
                  buttonText.value = `重新发送：${count}s`;
                }
              }, 1000);
            } else {
              let count2 = 60;
              isShowCount2.value = true;
              const timer2 = setInterval(() => {
                count2--;
                if (count2 === 0) {
                  clearInterval(timer2);
                  buttonText2.value = "";
                  isShowCount2.value = false;
                } else {
                  buttonText2.value = `重新发送：${count2}s`;
                }
              }, 1000);
            }
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.errmsg;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    // 腾讯验证
    const callback = (res) => {
      console.log("验证结果", res);
      // 处理验证码验证结果
      getCode(res.randstr,res.ticket);
    };
    // 腾讯验证
    const loadErrorCallback = () => {
      const appid = "191731340";
      const ticket =
        "terror_1001_" + appid + "_" + Math.floor(new Date().getTime() / 1000);
      callback({
        ret: 0,
        randstr: "@" + Math.random().toString(36).substr(2),
        ticket: ticket,
        errorCode: 1001,
        errorMessage: "jsload_error",
      });
    };
    // 腾讯验证
    const getCaptcha = () => {
      try {
        const captcha = new window.TencentCaptcha("199752961", callback, {});
        captcha.show();
      } catch (error) {
        loadErrorCallback();
      }
    };
    const getToast = () => {
      showMessageModal.value = true;
      setTimeout(() => {
        showMessageModal.value = false;
      }, 1500);
      msg.value = "手机号码格式错误";
    };

    const getToast2 = () => {
      showMessageModal.value = true;
      setTimeout(() => {
        showMessageModal.value = false;
      }, 1500);
      msg.value = "请先完善信息";
    };

    const changePassword = () => {
      const codestr = codeNumber2.value.toString();
      const obj = {
        phone: phoneNumber2.value,
        code: codestr,
        password: newPassword.value,
      };
      store
        .dispatch("passWordChange", { data: obj })
        .then((data) => {
          if (data.errno === 0) {
            returnLogin();
            // console.log(data)
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.errmsg;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      const obj2 = {
        code: "W100016",
        rcode: "",
        dcode: "",
      };
      report(obj2);
    };
    const getUserInfo = () => {
      const obj = {};
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("fetchCurrentUser", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.errmsg;
          } else {
            store.state.user = { isLogin: true, ...data.data };
            localStorage.setItem("userImg", data.data.FCoverPath);
            store.state.user.img = data.data.FCoverPath;
            store.state.user.userCode = data.data.FUserCode;
            localStorage.setItem("userCode", data.data.FUserCode);
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const toLogin = () => {
      if (smsLogin.value) {
        const obj = {
          phone: phoneNumber.value,
          code: codeNumber.value.toString(),
        };
        // const paramStr = JSON.stringify(obj)
        store
          .dispatch("login", { data: obj })
          .then((data) => {
            if (data.errno === 0) {
              getUserInfo();
              closeLoginModal();
              codeNumber.value = "";
            } else {
              showMessageModal.value = true;
              setTimeout(() => {
                showMessageModal.value = false;
              }, 1500);
              msg.value = data.errmsg;
            }
          })
          .catch((e) => {
            console.log(e);
          });

        const obj2 = {
          code: "W100012",
          rcode: "",
          dcode: "",
        };
        report(obj2);
      } else {
        const obj = {
          phone: phoneNumber.value,
          password: passwordNumber.value,
        };
        store
          .dispatch("passWordLogin", { payload: obj })
          .then((data) => {
            if (data.errno === 0) {
              // console.log(data)
              getUserInfo();
              closeLoginModal();
              passwordNumber.value = "";
            } else {
              showMessageModal.value = true;
              setTimeout(() => {
                showMessageModal.value = false;
              }, 1500);
              msg.value = data.errmsg;
            }
          })
          .catch((e) => {
            console.log(e);
          });

        const obj2 = {
          code: "W100013",
          rcode: "",
          dcode: "",
        };
        report(obj2);
      }
    };
/**
 * 扫码登录 start
 */
    const qrcodeStatus = ref(""); // 二维码状态
    const qrcodeImg = ref("");
    const myTime = ref<number>();
    console.log(myTime.value);
    const onclickChangeLoginIcon = async (num) => {
      if(num === 1) isAppScan.value = !isAppScan.value
      qrcodeStatus.value = "" // 失效刷新时 状态重置
      if(isAppScan.value){
        let codeUrl = "";
        store.dispatch("getAppCode", {}).then(async(data) => {
          if (data.code == 0) {
            codeUrl = data.data.uuid
            qrcodeImg.value = await QRCode.toDataURL(codeUrl); // 生成二维码链接
          }else{
            console.log(data.message);
          }
        })
        
        myTime.value = setInterval(function () {
          console.log("开始轮训");
          const obj = {
            "uuid":codeUrl
          }
          store.dispatch("getLoginStatus", {"uuid":codeUrl}).then((data) => {
            if (data.code == 0) {
              qrcodeStatus.value = data.data.status
              switch (qrcodeStatus.value) {
                case "pending":
                  console.log("等待用户扫码，不做处理");
                  break;
                case "scan":
                  console.log("扫码成功，请在手机上确认登录");
                  break;
                case "success":
                  console.log("登录成功，处理登录流程");
                  localStorage.setItem('token', data.data.token);  // 设置localStorage存储token
                  axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`; //jwt鉴权
                  getUserInfo();
                  closeLoginModal();
                  break;
                case "invalid":
                  console.log("二维码失效,停止轮询");
                  clearInterval(myTime.value)
                  break;
                default:
                  break;
              }
             
            }else{
              console.log(data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
        },2000)
      }
    }
    watch(isAppScan,value=>{
      console.log("监听isAppScan："+value);
      if (!isAppScan.value && myTime.value) {
        console.log("销毁定时器"+myTime.value);
        clearInterval(myTime.value)
      }
    })
/**
 * 扫码登录 end
 */
    // 登录手机号
    const inputPClass = ref("flex-row-center f18-400-33 login-input");
    const changePStyle = () => {
      inputPClass.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restorePStyle = () => {
      inputPClass.value = "flex-row-center f18-400-33 login-input";
    };

    // 登录验证码
    const inputSClass = ref("flex-row-center f18-400-33 login-input");
    const changeSStyle = () => {
      inputSClass.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restoreSStyle = () => {
      inputSClass.value = "flex-row-center f18-400-33 login-input";
    };

    // 登录密码
    const inputKClass = ref("flex-row-center f18-400-33 login-input");
    const changeKStyle = () => {
      inputKClass.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restoreKStyle = () => {
      inputKClass.value = "flex-row-center f18-400-33 login-input";
    };

    // 改密码手机号
    const inputPPClass = ref("flex-row-center f18-400-33 login-input");
    const changePPStyle = () => {
      inputPPClass.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restorePPStyle = () => {
      inputPPClass.value = "flex-row-center f18-400-33 login-input";
    };

    // 改密码密码1
    const inputPK1Class = ref("flex-row-center f18-400-33 login-input");
    const changePK1Style = () => {
      inputPK1Class.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restorePK1Style = () => {
      inputPK1Class.value = "flex-row-center f18-400-33 login-input";
    };

    // 改密码密码2
    const inputPK2Class = ref("flex-row-center f18-400-33 login-input");
    const changePK2Style = () => {
      inputPK2Class.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restorePK2Style = () => {
      inputPK2Class.value = "flex-row-center f18-400-33 login-input";
    };

    // 改密码验证码
    const inputPSClass = ref("flex-row-center f18-400-33 login-input");
    const changePSStyle = () => {
      inputPSClass.value = "flex-row-center f18-400-33 login-input-action";
    };
    const restorePSStyle = () => {
      inputPSClass.value = "flex-row-center f18-400-33 login-input";
    };

    const login = () => {
      // 实现登录逻辑
      console.log("登录成功");
      emit("loginSuccess");
    };

    const toggleLoginType = () => {
      smsLogin.value = !smsLogin.value;
      passwordNumber.value = "";
      codeNumber.value = "";
      isCodeNumberValid.value = false;
      if (!smsLogin.value) {
        const obj = {
          code: "W100009",
          rcode: "",
          dcode: "",
        };
        report(obj);
      } else {
        const obj = {
          code: "W100010",
          rcode: "",
          dcode: "",
        };
        report(obj);
      }
    };

    const countDown = ref(0);

    const sendVerificationCode = () => {
      countDown.value = 60;
      const timer = setInterval(() => {
        countDown.value--;
        if (countDown.value === 0) {
          clearInterval(timer);
        }
      }, 1000);
    };
    const toAppleLogin = () => {
      window.open(
        "https://appleid.apple.com/auth/authorize?client_id=cdzq.bysj.com.clik&redirect_uri=https%3A%2F%2Foaapi.7ugame.cn%2Fqyyx%2Fauth%2FappleAuthTrans&response_type=code%20id_token&state=pLhHQFOMppVpz6LqLhL6B0ZZZZZLhMeF0&response_mode=form_post&frame_id=2af7eae4-2a4d-4631-ab50-794c4df55662&m=11&v=1.5.5"
      );

      localStorage.setItem("page", window.location.href);
      localStorage.setItem("state", "");
    };
    onMounted(() => {
      if (
        window.location.href.indexOf("oa") > -1 ||
        window.location.href.includes("localhost")
      ) {
        _url.value = "https://oaweb.7ugame.cn";
      }
    });

    return {
      toAppleLogin,
      getUserInfo,
      validateCode2,
      toyhxy,
      toyszc,
      changePassword,
      toLogin,
      isSendMsg,
      validatePassword,
      validatePassword2,
      returnLogin,
      toChangePassword,
      newPassword,
      newPassword2,
      isChangePassword,
      isPasswordVisible,
      isPasswordVisible2,
      isPasswordVisible3,
      passwordInput,
      passwordInput2,
      passwordInput3,
      togglePasswordVisibility,
      togglePasswordVisibility2,
      togglePasswordVisibility3,
      getToast,
      getToast2,
      isShowCount,
      isShowCount2,
      buttonText,
      buttonText2,
      msg,
      showMessageModal,
      isHover,
      closeLoginModal,
      getCode,
      getCaptcha,
      validatePhone,
      validatePhone2,
      validateCode,
      phoneNumber,
      codeNumber,
      passwordNumber,
      isCodeNumberValid,
      isPasswordValid,
      isPasswordValid2,
      isPasswordValid3,
      isPhoneNumberValid,
      phoneNumber2,
      codeNumber2,
      isCodeNumberValid2,
      isPhoneNumberValid2,
      login,

      smsLogin,
      inputPClass,
      changePStyle,
      restorePStyle,
      inputSClass,
      changeSStyle,
      restoreSStyle,
      inputKClass,
      changeKStyle,
      restoreKStyle,
      // PP PK1 PK2 PS
      inputPPClass,
      changePPStyle,
      restorePPStyle,
      inputPK1Class,
      changePK1Style,
      restorePK1Style,
      inputPK2Class,
      changePK2Style,
      restorePK2Style,
      inputPSClass,
      changePSStyle,
      restorePSStyle,

      toggleLoginType,
      countDown,
      sendVerificationCode,
      isChangePasswordSubmit,
      isAppScan,
      onclickChangeLoginIcon,
      qrcodeImg,
      qrcodeStatus
    };
  },
});
