import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_header = _resolveComponent("global-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args)))
  }, [
    _createVNode(_component_global_header, {
      user: _ctx.currentUser,
      isFocus: _ctx.isFocus,
      onChangeFocusTrue: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFocus = true)),
      onChangeFocusFalse: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocus = false))
    }, null, 8, ["user", "isFocus"]),
    _createVNode(_component_router_view)
  ]))
}