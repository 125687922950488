
import Login from "./components/Login.vue";
import './css/base.css'
import { useStore } from "vuex";
import GlobalHeader from "./components/GlobalHeader.vue";
import Index from "./views/Index.vue";
import creatwMessage from "./components/createMessage";
import { defineComponent, computed, onUpdated, onMounted, watch, ref, toRefs } from "vue";
import { GlobalDataProps } from "./store";

function generateMixed(n: number): string {
  const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let res = "";
  for (let i = 0; i < n; i++) {
    const id = Math.floor(Math.random() * 36); res += chars[id];
  }
  return res;
}

export default defineComponent({
  name: "App",
  components: {
    GlobalHeader,
    // Index,
  },


  setup() {
    const store = useStore<GlobalDataProps>();
    const isLogin = computed(() => store.state.user.isLogin);
    const currentUser = computed(() => store.state.user);
    const error = computed(() => store.state.error);
    const isFocus = ref(false)
    const isShowInfo = ref(false)
    
    const getRegist = (code) => {
      const obj = {
        FSource: 4,
        FDeviceSN: code,
        FUserAgent: navigator.userAgent
      };
      const paramStr = JSON.stringify(obj);
      store.dispatch("regist", { data: paramStr })
        .then((data) => {
          if (data.code == 0) {
            localStorage.setItem('FReportSN', data.data.FReportSN);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    const handleCloseModal = ($event) => {
      // 判断点击的元素是否是弹框或置顶的几个div元素，如果不是则关闭弹框
      // 历史记录
      if (!$event.target.closest('.header-input')) {
        // 关闭弹框的逻辑
          isFocus.value = false
      }
     
    }

    onMounted(() => {

      if (isLogin.value && localStorage.getItem('userCode') && localStorage.getItem('userCode') != '') {
        const code = localStorage.getItem('userCode')
        getRegist(code)
      } else {
        if (localStorage.getItem('random')) {
          const code = localStorage.getItem('random')
          getRegist(code)
        } else {
          const code = generateMixed(10)
          localStorage.setItem('random', code);
          getRegist(code)
        }

      }


    });

    watch(
      () => error.value.status,
      () => {
        const { status, message } = error.value;
        if (status && message) {
          creatwMessage(message, "error");
        }
      }
    );

    return {
      isShowInfo, //头部消息通知
      isFocus, //头部历史记录
      handleCloseModal,
      getRegist,
      isLogin,
      currentUser,
    };

  },
});
