
// 节流函数
const throttle = (func: Function, delay: number) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    func(...args);
  };
};
import Message from "../components/Message.vue";
import Login from "../components/Login.vue";
import creatwMessage from "../components/createMessage";
import { useRoute, useRouter } from "vue-router";
import {
  defineComponent,
  computed,
  onUpdated,
  onMounted,
  watch,
  ref,
  toRefs,
  nextTick,
  inject,
} from "vue";
import "../css/base.css";
import { useStore } from "vuex";

import { GlobalDataProps } from "../store";
import BasicInfo from "../components/BasicInfo.vue";

export default defineComponent({
  name: "SearchDetail",
  components: {
    Login,
    Message,
    BasicInfo,
  },
  // mounted() {
  //     const infoItem = document.querySelector('.info-item') as HTMLElement;

  //     infoItem.addEventListener('scroll', () => {

  //         if (infoItem.scrollTop > infoItem.clientHeight * 3) {
  //             this.showScrollTopButton = true
  //         }
  //         if (infoItem.scrollTop <= infoItem.clientHeight * 3) {
  //             this.showScrollTopButton = false
  //         }
  //         const distanceToBottom = infoItem.scrollHeight - infoItem.scrollTop - infoItem.clientHeight;
  //         if (distanceToBottom <= 100) {
  //             this.getArticalList()
  //         }
  //     });

  // },
  setup() {
    const searchInfo = ref("");
    const userCode = localStorage.getItem("userCode");
    const gameName = ref();
    const gameCode = ref();
    const isShowChangeGame = ref(false);
    const isMoreData = ref(true);
    const msg = ref("");
    const FPage = ref(1);
    const FPageSize = ref(20);
    const FLabelValue = ref("-1");
    const showLoginModal = ref(false);
    const showMessageModal = ref(false);
    const handleLoginSuccess = () => {
      showLoginModal.value = false;
      // 处理登录成功后的逻辑
    };
    const store = useStore<GlobalDataProps>();
    const currentUser = computed(() => store.state.user);
    const listData = ref<any[]>([]);
    const gameList = ref<any[]>([]);
    const gameListItem = ref<any[]>([]);
    const error = computed(() => store.state.error);
    const isLoading = computed(() => store.state.loading);
    const isLogin = computed(() => store.state.user.isLogin);
    const isShowGzh = ref(false);
    const isShowDy = ref(false);
    const activeTab = ref("热门");
    const router = useRouter();
    const toDetail = (id, type) => {
      router.push({ path: `/articleDetail/${id}/${type}` });
    };

    const markLikeState = (id, status) => {
      const obj = {
        FType: 4,
        FReferCode: id,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("behaviorLike", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const changeSBg = (type) => {
      const elements = document.querySelectorAll(".share-item");
      elements.forEach((element) => {
        if (element && element.textContent === type) {
          element.classList.remove("bgfff");
          element.classList.add("bgff6");
        }
      });
    };

    const changeSBg2 = (type) => {
      const elements = document.querySelectorAll(".share-item");
      elements.forEach((element) => {
        if (element && element.textContent === type) {
          element.classList.remove("bgff6");
          element.classList.add("bgfff");
        }
      });
    };
    const changeLikeStatus = (id, type) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      //  type:2取消喜欢 1喜欢
      const data = listData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.FIsLike = !element.FIsLike;
          if (type == 2) {
            element.FLikeCount--;
          } else if (type == 1) {
            element.FLikeCount++;
          }
        }
      });
      markLikeState(id, type);
    };

    const markFollowState = (status, uid, id) => {
      const obj = {
        FType: 2,
        FRelationCode: uid,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("focusSetup", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          } else {
            //  type:2取消关注 1关注
            const data = listData.value;
            data.forEach((element) => {
              if (element.FId == id) {
                element.FFollowStatus = status;
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const changeFollowStatus = (id, type, uid) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      markFollowState(type, uid, id);
    };

    const markState = (id, status) => {
      const obj = {
        FType: 3,
        FReferCode: id,
        FStatus: status,
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("uninterested", { data: paramStr })
        .then((data) => {
          if (data.code != 0) {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const getArtical = () => {
      const obj = {
        keyword: searchInfo.value,
        page: FPage.value,
        limit: FPageSize.value,
        searchType: "richText",
      };
      const paramStr = JSON.stringify(obj);
      store
        .dispatch("searchArtical", { data: paramStr })
        .then((data) => {
          if (data.code === 0) {
            if (data.data.data.length == 0) {
              isMoreData.value = false;
              return;
            }
            listData.value = [...listData.value, ...data.data.data];

            const td = listData.value;
            td.forEach((item) => {
              item.FTopics.forEach((item2) => {
                item2.isHover = false;
              });
            });
          } else {
            showMessageModal.value = true;
            setTimeout(() => {
              showMessageModal.value = false;
            }, 1500);
            msg.value = data.message;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      FPage.value += 1;
    };
    const clearSearchInfo = () => {
      const sii = document.querySelector(".search-input-info") as HTMLElement;
      sii.focus();
      searchInfo.value = "";
      // listData.value = [];
      // FPage.value = 1;
      // getArtical();
      // isMoreData.value = true;
      
    };
    const getSearchArtical = () => {
      listData.value = [];
      FPage.value = 1;
      isMoreData.value = true;
      getArtical();
    };
    const { getArticalList } = toRefs({
      getArticalList: throttle(() => {
        if (isMoreData.value) {
          getArtical();
        }
      }, 3000),
    });

    const changeBg = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.background = "#E5E5E5";
    };

    const changeBg2 = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.background = "#fff";
    };
    const hideContent = (id) => {
      if (!isLogin.value) {
        showLoginModal.value = true;
        return;
      }

      const data = listData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isHide = true;
        }
      });
      markState(id, 1);
    };
    const openContent = (id) => {
      const data = listData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isHide = false;
          element.isShowDislike = false;
        }
      });
      markState(id, 2);
    };

    const showDislike = (id) => {
      const data = listData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isShowDislike = true;
        }
      });
    };

    const closeDislike = (id) => {
      const data = listData.value;
      data.forEach((element) => {
        if (element.FId == id) {
          element.isShowDislike = false;
        }
      });
    };

    const toSearchDetail = (info) => {
      if (isLogin.value) {
        console.log("1");
        if (localStorage.getItem("searchInfo")) {
          console.log("2");
          const originalSearchInfo = localStorage.getItem("searchInfo");
          const originalSearchInfoArray = originalSearchInfo
            ? originalSearchInfo.split(",")
            : [];
          if (!originalSearchInfoArray.includes(info)) {
            originalSearchInfoArray.push(info);
            console.log("3");
          }
          const newSearchInfo = originalSearchInfoArray.join(",");
          localStorage.setItem("searchInfo", newSearchInfo);
        } else {
          const newSearchInfo = info;
          localStorage.setItem("searchInfo", newSearchInfo);
        }
        const sarr = localStorage.getItem("searchInfo");
      }
      searchInfo.value = info;
      FPage.value = 1;
      listData.value = [];
      isMoreData.value = true;
      getArtical();
    };
    const infoItem = ref<HTMLElement | null>(null);
    const showScrollTopButton = ref(false);

    const handleScroll = () => {
      if (infoItem.value) {
        if (infoItem.value.scrollTop > infoItem.value.clientHeight * 3) {
          showScrollTopButton.value = true;
        } else {
          showScrollTopButton.value = false;
        }

        const distanceToBottom =
          infoItem.value.scrollHeight -
          infoItem.value.scrollTop -
          infoItem.value.clientHeight;
        if (distanceToBottom <= 100) {
          getArticalList.value();
        }
      }
    };

    const scrollTop = () => {
      if (infoItem.value) {
        infoItem.value.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    const changeColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#68A5E1";
    };
    const changeColorTip = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#68A5E1";
    };

    const revertColor = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "";
    };

    const revertColorTip = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      target.style.color = "#999999";
    };

    onMounted(() => {
      const route = useRoute();
      // if (route.query.info) {
      searchInfo.value = route.params.info as string;
      getArtical();
      // }

      const element = document.querySelector(".info-item") as HTMLElement;
      infoItem.value = element;
      infoItem.value.addEventListener("scroll", handleScroll);
    });
    watch(
      () => error.value.status,
      () => {
        const { status, message } = error.value;
        if (status && message) {
          creatwMessage(message, "error");
        }
      }
    );

    return {
      changeColorTip,
      changeColor,
      revertColor,
      revertColorTip,
      showScrollTopButton,
      scrollTop,
      toSearchDetail,
      getSearchArtical,
      clearSearchInfo,
      toDetail,
      isMoreData,
      searchInfo,
      gameName,
      gameCode,
      isShowChangeGame,
      gameList,
      gameListItem,
      userCode,
      changeFollowStatus,
      markLikeState,
      changeLikeStatus,
      markState,
      getArtical,
      FPage,
      FPageSize,
      msg,
      showMessageModal,
      showLoginModal,
      handleLoginSuccess,
      openContent,
      hideContent,
      changeSBg,
      changeSBg2,
      changeBg,
      changeBg2,
      listData,
      showDislike,
      closeDislike,
      isShowGzh,
      isShowDy,
      activeTab,
      getArticalList,
      currentUser,
      isLoading,
      isLogin,
      error,
    };
  },
});
