import axios from 'axios';
import { Md5 } from 'ts-md5'

import { createStore, Commit, Module } from "vuex";
// import api from 'api';
// import { API_URL, API_ENDPOINTS } from './api.js';
// console.log(api);
const key = '0cf624ce1675f09aeef1a308e9d438f9'
const appid = '1006'

let _url = "https://api.7ugame.cn";
if (window.location.href.includes("oa") || window.location.href.includes("localhost")) {
  _url = "https://oaapi.7ugame.cn";
}
export interface UserProps {
  isLogin: boolean;
  nickName?: string;
  img?: string;
  userCode?: number;
  FTotalNum?: number;
  isSearch?: boolean;
}

export interface RefreshProps {
  message?: boolean;
  like?: boolean;
  user?: boolean;
  info?: boolean;
}

interface ImageProps {
  _id?: string;
  url?: string;
  createdAt?: string;
}

export interface ColumnProps {
  _id: string;
  title: string;
  avatar?: ImageProps;
  description: string;
}

export interface PostProps {
  _id: string;
  title: string;
  excerpt?: string; //摘要
  content: string;
  image?: ImageProps;
  createdAt: string;
  column: string;
}

export interface GlobalErrorProps {
  status: boolean;
  message?: string;
}

export interface GlobalDataProps {
  showLoginModal: boolean;
  error: GlobalErrorProps;
  token: string;
  loading: boolean;
  columns: ColumnProps[];
  posts: PostProps[];
  user: UserProps;
  refresh: RefreshProps;
}



function generateMixed(n: number): string {
  const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let res = "";
  for (let i = 0; i < n; i++) {
    const id = Math.floor(Math.random() * 36); res += chars[id];
  }
  return res;
}


// 封装的async await 异步处理方法
const getAndCommit = async (url: string, mutationName: string, commit: Commit) => {
  const { data } = await axios.get(url)
  commit(mutationName, data)
  return data
}
const getAndCommitNew = async (url: string, mutationName: string, commit: Commit, payload: any) => {
  const api = _url+url
  let params = {
    params: payload
  }
  const { data } = await axios.get(api,{params: payload})
  commit(mutationName, data)
  return data
}

const postAndCommitOld = async (url: string, mutationName: string, commit: Commit, payload: any) => {
  const config = {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
  };
  const api = _url+url
  const { data } = await axios.post(api, payload, config)
  commit(mutationName, data)
  return data
}

const postAndCommit = async (url: string, mutationName: string, commit: Commit, payload: any, mode?: any) => {
  const paramStr = payload
  const str1 = paramStr + key
  const signature = Md5.hashStr(str1).toLowerCase()
  const generateNum = generateMixed(5)
  const time = Date.now()
  const str = 'web' + time + generateNum
  const randNum = Md5.hashStr(str).toLowerCase()
  const config = {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'app-id': appid,
      'terminal-version': 1,
      'signature': signature,
      'random-number': randNum
    }
  };
  const obj = mode=="new" ? paramStr : {params: paramStr} // 请求api传参格式新老方式兼容
  const api = _url+url
  const { data } = await axios.post(api, obj, config)

  if (data.code === 501) {
    // 清除登录状态和个人信息
    localStorage.removeItem('token');
    localStorage.removeItem('userImg');
    commit('claerUserInfo')
  } else {
    // commit(mutationName, data)
  }
  return data
}


const reportAndCommit = async (url: string, mutationName: string, commit: Commit, payload: any) => {
  const dataObj: { [key: string]: any } = {};
  dataObj.FReportSN = localStorage.getItem('FReportSN')
  dataObj.FReportType = payload.FReportType
  dataObj.FEventSN = payload.FEventSN
  dataObj.FRelatedCode = payload.FRelatedCode
  dataObj.FRelatedDeviceCode = payload.FRelatedDeviceCode
  dataObj.FEventTime = Date.now();
  dataObj.FSessionId = Date.now() + 'web';

  // {
  //   FReportType: 2,
  //   FEventSN: code,
  //   FRelatedCode:
  //   FRelatedDeviceCode:
  // }


  const paramStr = JSON.stringify(dataObj);

  // 为请求头服务
  const str1 = paramStr + key
  const signature = Md5.hashStr(str1).toLowerCase()
  const generateNum = generateMixed(5)
  const time = Date.now()
  const str = 'web' + time + generateNum
  const randNum = Md5.hashStr(str).toLowerCase()
  const config = {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'app-id': appid,
      'terminal-version': 1,
      'signature': signature,
      'random-number': randNum
    }
  };
  const obj = {
    params: paramStr,
  }
  const api = _url+url
  const { data } = await axios.post(api, obj, config)

  return data
}





const store = createStore<GlobalDataProps>({
  state: {
    refresh:{
      message:false,
      info:false,
      like:false,
      user:false,
    },

    showLoginModal: false,
    error: { status: false },
    token: localStorage.getItem('token') || '', // 初始化时获取localStorage里的token
    loading: false,
    columns: [],
    posts: [],
    user: {
      isLogin: !!localStorage.getItem('token'),
      img: localStorage.getItem('userImg') || '',
      FTotalNum: 0
    },
  },
  mutations: {
    setFTotalNum(state, newValue) {
      state.user.FTotalNum = newValue;
    },
    setIsSearch(state, newValue) {
      state.user.isSearch = newValue;
    },

    claerUserInfo(state) {
      state.user.isLogin = false;
      state.user.img = '';
    },
    setMessage(state,value){
      state.refresh.message = value;
    },
    setInfo(state,value){
      state.refresh.info = value;
    },
    setUser(state,value){
      state.refresh.user = value;
    },
    setLike(state,value){
      state.refresh.like = value;
    },

    setShowLoginModal(state, value) {
      state.showLoginModal = value;
    },
    // login(state) {
    //   state.user = { ...state.user, isLogin: true, name: "Young" };
    // },
    createPost(state, newPost) {
      state.posts.push(newPost);
    },
    //同步 更新state中的columns数据，当调用fetchColumns方法时，会将获取到的数据更新到state中的columns属性中，从而实现对栏目数据的更新操作。
    fetchColumns(state, rawData) {
      state.columns = rawData.data.list;
    },
    fetchColumn(state, rawData) {
      state.columns = [rawData.data];
    },
    fetchPosts(state, rawData) {
      state.posts = rawData.data.list;
    },
    setLoading(state, status) {
      state.loading = status;
    },
    setError(state, e: GlobalErrorProps) {
      state.error = e;
    },
    fetchCurrentUser(state, rawData) {
      // console.log('rawData',rawData)
      // state.user = { isLogin: true, ...rawData };
      // localStorage.setItem('userImg', rawData.data.FCoverPath);
      // state.user.img = rawData.data.FCoverPath;

    },
    logout(state, rawData) {
      state.user = { isLogin: false };
      state.token = '';
      state.user.img = '',
      state.user.isLogin = false,
      state.user.FTotalNum = 0,
      localStorage.setItem('userCode', '');
      localStorage.setItem('userImg', '');
      localStorage.setItem('token', '');  // 设置localStorage存储token
      axios.defaults.headers.common['Authorization'] = `Bearer  `; //jwt鉴权
    },

    login(state, rawData) {
      const { token } = rawData.data;
      if (rawData.errno == 0) {
        state.token = token;
        state.user.isLogin = true,
        localStorage.setItem('token', token);  // 设置localStorage存储token
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; //jwt鉴权
      }

    },

    passWordLogin(state, rawData) {
      const  token  = rawData.data;
      if (rawData.errno == 0) {
        state.user.isLogin = true,
        state.token = token;
        localStorage.setItem('token', token);  // 设置localStorage存储token
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; //jwt鉴权
      }

    },



    appleRegister(state, rawData) {
      const { token } = rawData.data;
      // if (rawData.errno == 0) {
        state.token = token;
        state.user.isLogin = true,
        state.user.FTotalNum = 0,
        localStorage.setItem('token', token);  // 设置localStorage存储token
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; //jwt鉴权
      // }

    },
    // signUp(state,rawData){

    // }
  },


  // actions 发送异步请求
  actions: {
    // 改造后并且封装了异步方法的请求
    // 用于发送异步请求获取栏目数据，并通过commit方法调用mutations中的fetchColumns方法来更新state中的columns数据
    fetchColumns({ commit }) {
      getAndCommit('/api/columns', 'fetchColumns', commit)
    },
    // 改造前的异步请求
    // fetchColumns({ commit }) {
    //   axios.get('./api/columns').then(res => {
    //     commit('fetchColumns', res.data)
    //   })
    // },

    // 改造后没有封装异步方法的请求
    // async fetchColumns({ commit }) {
    //   const { data } = axios.get('./api/columns')
    //   commit('fetchColumns', data)
    // },

    // fetchColumn({ commit }, cid) {
    //   getAndCommit(`/api/columns/${cid}`, 'fetchColumn', commit)
    // },
    // fetchPosts({ commit }, cid) {
    //   getAndCommit(`/api/columns/${cid}/posts`, 'fetchPosts', commit)
    // },
    


    fetchCurrentUser({ commit }, { data }) {
      return postAndCommit('/web/user/home', 'fetchCurrentUser', commit, data)
    },

    logout({ commit }, { payload }) {
      return postAndCommitOld('/qyyx/auth/logout', 'logout', commit, payload)
    },

    login({ commit }, { data }) {
      return postAndCommitOld('/qyyx/auth/phoneCode', 'login', commit, data)
    },
    passWordLogin({ commit }, { payload }) {
      return postAndCommitOld('/qyyx/auth/login', 'passWordLogin', commit, payload)
    },

    // 苹果登录
    appleRegister({ commit }, { data }) {
      return postAndCommitOld('/qyyx/auth/appleRegister', 'appleRegister', commit, data)
    },
   
    passWordChange({ commit }, { data }) {
      return postAndCommitOld('/qyyx/auth/setPassword', '', commit, data)
    },
    loginAndFetch({ dispatch }, { loginData }) {
      return dispatch('login', { payload: loginData })
      // .then((data) => {

      //   if (data.code === 0) {
      //     const obj = {}
      //     const paramStr = JSON.stringify(obj)

      //     return dispatch('fetchCurrentUser', { payload: paramStr })
      //   }

      // })

    },
    passWordLoginAndFetch({ dispatch }, { loginData }) {
      return dispatch('passWordLogin', { payload: loginData }).then(() => {
        return dispatch('fetchCurrentUser', { payload: '' })
      })

    },

    sentCaptcha({ commit }, { data }) {
      return postAndCommitOld('/qyyx/auth/sentCaptcha', '', commit, data)
    },

    richTextList({ commit }, { data }) {
      return postAndCommit('/web/richText/list', '', commit, data)
    },

    //不感兴趣
    uninterested({ commit }, { data }) {
      return postAndCommit('/web/behavior/uninterested', '', commit, data)
    },
    // 视频播放
    antiLeech({ commit }, { data }) {
      return postAndCommit('/web/common/antiLeech', '', commit, data)
    },
    // 文章点赞
    behaviorLike({ commit }, { data }) {
      return postAndCommit('/web/behavior/like', '', commit, data)
    },
    // 文章收藏
    behaviorCollect({ commit }, { data }) {
      return postAndCommit('/web/behavior/collect', '', commit, data)
    },
    shareArtical({ commit }, { data }) {
      return postAndCommit('/web/behavior/share', '', commit, data)
    },
    // 文章回复
    CommentAdd({ commit }, { data }) {
      return postAndCommit('/web/comment/add', '', commit, data)
    },

    //  关注用户
    focusSetup({ commit }, { data }) {
      return postAndCommit('/web/user/focusSetup', '', commit, data)
    },

    // 举报列表
    tipOffItemCommon({ commit }, { data }) {
      return postAndCommit('/web/behavior/tipOffItemCommon', '', commit, data)
    },
    // 评论举报
    tipOffCommon({ commit }, { data }) {
      return postAndCommit('/web/behavior/tipOffCommon', '', commit, data)
    },
    // 评论置顶
    commentTop({ commit }, { data }) {
      return postAndCommit('/web/comment/top', '', commit, data)
    },
    // 评论删除
    commentDelete({ commit }, { data }) {
      return postAndCommit('/web/comment/delete', '', commit, data)
    },

    richTextDetail({ commit }, { data }) {
      return postAndCommit('/web/richText/detail', '', commit, data)
    },

    gameList({ commit }, { data }) {
      return postAndCommit('/web/recommendTool/gameList', '', commit, data)
    },
    gameListItem({ commit }, { data }) {
      return postAndCommit('/web/recommendTool/itemList', '', commit, data)
    },

    richTextComment({ commit }, { data }) {
      return postAndCommit('/web/comment/list', '', commit, data)
    },

    // 图鉴分类
    gameToolCategories({ commit }, { data }) {
      return postAndCommit('/terminal/gameToolGuide/categories', '', commit, data)
    },
    // 图鉴列表
    gameToolList({ commit }, { data }) {
      return postAndCommit('/terminal/gameToolGuide/list', '', commit, data)
    },
    // 图鉴详情
    gameToolDetail({ commit }, { data }) {
      return postAndCommit('/terminal/gameToolGuide/detail', '', commit, data)
    },

    // 消息通知获取未读数
    unReads({ commit }, { data }) {
      return postAndCommit('/web/message/unReads', '', commit, data)
    },

    // 评论列表
    comment({ commit }, { data }) {
      return postAndCommit('/web/message/comment', '', commit, data)
    },
    // 赞列表
    likeCollect({ commit }, { data }) {
      return postAndCommit('/web/message/likeCollect', '', commit, data)
    },
    // 关注列表
    focusMessageList({ commit }, { data }) {
      return postAndCommit('/web/message/focusMessageList', '', commit, data)
    },
    // 系统通知列表
    systemMessageList({ commit }, { data }) {
      return postAndCommit('/web/message/systemMessageList', '', commit, data)
    },
    // 一键已读
    clearAll({ commit }, { data }) {
      return postAndCommit('/web/message/clearAll', '', commit, data)
    },
    // 搜索
    searchArtical({ commit }, { data }) {
      return postAndCommit('/web/search/search', '', commit, data)
    },


    // 
    // 回复
    /**
        * 路由：/web/comment/reply
        * 入参说明
        * FirstId = 25  一级评论id
        * FType = 1 被评论业务类型（1=视频，2=图文，3=评测，4=文章）
        * FTopId="" 需要置顶的二级评论id（不需要传空）
        * FReferCode = '' 被评论业务编号（视频ID/图文ID/评测ID/文章ID）
        * FPage = 1 页码
        * FPageSize = 10 每页大小
        */
    commentReply({ commit }, { data }) {
      return postAndCommit('/web/comment/reply', '', commit, data)
    },

    // 上报
    regist({ commit }, { data }) {
      return postAndCommit('/terminal/report/regist', '', commit, data)
    },

    // 上报
    point({ commit }, { data }) {
      return reportAndCommit('/terminal/report/point', '', commit, data)
    },
    // 获取app扫码登录二维码
    getAppCode({ commit }, { data }) {
      return postAndCommit('/v2/qr-login/generate', '', commit, data)
    },
    // 轮询扫码登录状态
    getLoginStatus({ commit },  data) {
      return postAndCommit('/v2/qr-login/status', '', commit, data, "new")
    },
    // 获取安卓安装包
    getAppPack({ commit }, data) {
      return getAndCommitNew(`/qyyx/sys/pack`, '', commit, data)
    },
    

  },
  getters: {

    getColumnById: (state) => (id: string) => {
      return state.columns.find((c) => c._id === id);
    },
    getPostByCid: (state) => (cid: string) => {
      return state.posts.filter((post) => post.column === cid);
    },
  },


});

/**
 * 
Actions：Actions是用来处理异步操作的方法，比如发送网络请求获取数据。在Actions中可以通过commit方法调用Mutations中的方法来更新state中的数据。

Mutations：Mutations是用来更新state中的数据的方法。Mutations中的方法应该是同步的，用来更新state中的数据的状态。

Getters：Getters是用来对state中的数据进行计算或筛选的方法。Getters可以根据state中的数据返回新的数据，但不会修改state中的数据。

通过将数据的获取和更新分离到Actions和Mutations中，可以使代码更加清晰和易于维护。Actions负责处理异步操作，而Mutations负责更新state中的数据，Getters则用来对数据进行计算和筛选，从而实现数据的获取和更新分离。
 */
export default store;
