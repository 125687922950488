import { createRouter, createWebHistory,createWebHashHistory  } from "vue-router";
import { defineAsyncComponent } from 'vue';
import ToolDetail from "./views/ToolDetail.vue";
import Index from "./views/Index.vue";
import InfoDetail from "./views/InfoDetail.vue";
import SearchDetail from "./views/SearchDetail.vue";
import AppleSignIn from "./views/AppleSignIn.vue";
import Login from "./views/Login.vue";
import store from "./store"
import ArticleDetail from './views/ArticleDetail.vue';
import SkeletonDemo from './views/test.vue';
const routerHistory = createWebHistory();
const router = createRouter({
  // history: routerHistory,
  // history: createWebHashHistory(),
  history:createWebHashHistory(),
  routes: [
    // 首页内容
    {
      path: "/",
      name: "index",
      component: Index,
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: Login,
    //   // meta: { requiredLogin: true }
    //   // meta:{ redirectAlreadyLogin: true } // 是否已经登录
    // },
    {
      path: '/infoDetail/:type',
      name: "infoDetail",
      component: InfoDetail,
    
    },
    {
      path: '/appleSignIn/:state/:code/:id_token',
      name: "appleSignIn",
      component: AppleSignIn,
    
    },
    {
      path: '/articleDetail/:id/:type',
      name: "articleDetail",
      component: ArticleDetail,
    
    },
    {
      path: '/searchDetail/:info',
      name: "searchDetail",
      component: SearchDetail,
    
    },
    {
      path: '/toolDetail/:id/:code',
      name: "toolDetail",
      component: ToolDetail,
    
    },
    {
      path: '/skeletonDemo',
      name: "skeletonDemo",
      component: SkeletonDemo,
    
    }
    
    
  ],
});

// 配置全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 路由元信息设置权限
  // 如果当前页面需要登录，且当前用户没有登录，则自动前往登录界面
  // 如果当前页面需要登录，且当前用户已经登录，则自动前往首页
  
  if (to.meta.requiredLogin  && !store.state.user.isLogin) {
    store.commit('setShowLoginModal', true);
  //  next('/login')
  } else if (to.meta.redirectAlreadyLogin && store.state.user.isLogin) {
    next('/')
  } else {
    next()
  }
})
export default router;
