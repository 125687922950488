import { createApp } from 'vue';
import Message from "./Message.vue"
export type MessageType = 'success' | 'error' | 'default'

function createMessage (message: string, type: MessageType, timeout = 6000) {
    const messageInstance = createApp(Message,{
        message,
        type
    })
    const mountNode  = document.createElement('div')
    document.body.appendChild(mountNode)
    messageInstance.mount(mountNode)
    setTimeout(()=>{
        messageInstance.unmount()

        document.body.removeChild(mountNode)
    },timeout)
}

export default createMessage